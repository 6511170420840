import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../App/Navbar';
import Footer from '../App/Footer';
import API_Root from '../Constants/API_Root';
import { auth } from '../Authentication/firebase/firebase';

class Gateway extends Component {
    state = {
        userEmail: null,
        pocAccess: false,
        firstName: "",
        lastName: "",
        isLoggedIn: null,
    };

    componentDidMount() {
        const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
        if (!isLoggedIn) {
            this.props.history.push('/login');
        }
        this.setState({ isLoggedIn });

        auth.onAuthStateChanged(user => {
            if (user) {
                this.setState({
                    isLoggedIn: true,
                    userEmail: user.email,
                });
                
                this.fetchProfileInformation(this.state.userEmail)
                
                
            } else {
                this.setState({
                    isLoggedIn: false,
                    userEmail: null,
                });
            }
        });
        
    }

    fetchProfileInformation = (email) => {
        fetch(API_Root + 'get-profile-information', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
                ip_address: this.state.ip_address,
            }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                this.setState({
                    firstName: data.first_name || 'None',
                    lastName: data.last_name || 'None',
                    pocAccess: data.access || false
                }, () => {
                    const { pocAccess } = this.state;
                    console.log("pocaccess state", pocAccess)
                });
            })
            .catch(error => {
            });
    };

    render() {
        return (
            <div className='gateway-body'>
                <div className='portal-logo-container'>
                    <div className='logo' />
                    <div className='logo-text'>
                        <Link to="/" style={{ color: '#3E826D' }}>E&S Solutions</Link>
                    </div>
                </div>
                <div className='portal-main-container'>
                    <div className='portal-container'>

                        {/* myESRA Portal */}
                        <Link to="/chat" className='portal-box' style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div className='portal-box-content'>
                                <span style={{ fontSize: '16px' }}><i>Navigate to</i></span>
                                <h2>
                                    myESRA
                                    <span style={{ fontSize: '16px', verticalAlign: 'text-top' }}>®</span>
                                </h2>
                            </div>
                        </Link>

                        {/* privatESRA Portal */}
                        <Link to="/privatesra" className='portal-box' style={{ textDecoration: 'none', color: 'inherit' }}>
                            <div className='portal-box-content'>
                                <span style={{ fontSize: '16px' }}><i>Navigate to</i></span>
                                <h2>privatESRA</h2>
                            </div>
                        </Link>

                    </div>
                </div>
            </div>
        );
    }
}

export default Gateway;
