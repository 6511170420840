import React, { Component, useContext } from 'react'
import { Link } from 'react-router-dom';



class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDropdownVisible: false,
            showGetStartedButton: false, 
        };
    }

    componentDidMount() {
        this.setState({
            showGetStartedButton: this.shouldShowGetStartedButton() // Set based on the condition
        });
    }

    shouldShowGetStartedButton = () => {
        const today = new Date();
        const targetDate = new Date("2024-04-24");
        return today >= targetDate;
    };

    toggleDropdown = () => {
        this.setState(prevState => ({
            isDropdownVisible: !prevState.isDropdownVisible
        }));
    };

    scrollToTop = () => {
        window.scrollTo(0, 0);
    };
    render() {
        const { isDropdownVisible, showGetStartedButton } = this.state;
        
        return (
            
            <div className='navbar'>
                <div className='navbar-container'>
                    <div className='navbar-item'>
                        <div className='logo' />
                        <div className='logo-text'><Link to="/" style={{ color: '#3E826D' }}>E&S Solutions</Link></div>
                    </div>
                    <div className='navbar-item'>
                        <div className='menu-icon' onClick={this.toggleDropdown}>
                            <i className="fa fa-bars fa-2x"></i>
                        </div>
                        {isDropdownVisible && (
                            <div className='dropdown-content'>
                                <span onClick={this.props.scrollToTop}>
                                    <Link to="/" className="dropdown-link-item">What we do</Link>
                                </span>
                                <span onClick={this.props.scrollToTarget}>
                                    <Link to="/" className="dropdown-link-item">myESRA®</Link>
                                </span>
                                <Link to="/pricing" onClick={this.scrollToTop} className="dropdown-link-item">Pricing</Link>
                                <Link to="/about-us" onClick={this.scrollToTop} className="dropdown-link-item">About Us</Link>
                                <Link to="/faqs" onClick={this.scrollToTop} className="dropdown-link-item">FAQs</Link>
                                <Link to="/resources" onClick={this.scrollToTop} className="dropdown-link-item">Resources</Link>
                            </div>
                        )}
                        <div className='navbar-links'>
                            <div onClick={this.props.scrollToTop} style={{ cursor: 'pointer' }}><Link to="/">What we do</Link></div>
                            <div onClick={this.props.scrollToTarget} style={{ cursor: 'pointer' }}><Link to="/">myESRA®</Link></div>
                            <span><Link to="/pricing" onClick={this.scrollToTop}>Pricing</Link></span>
                            <span><Link to="/about-us" onClick={this.scrollToTop}>About Us</Link></span>
                            <span><Link to="/faqs" onClick={this.scrollToTop}>FAQs</Link></span>
                            <span><Link to="/resources" onClick={this.scrollToTop}>Resources</Link></span>
                        </div>
                        
                        <Link to="/portal" className="navbar-item-button">Get Started</Link>
                    </div>
                </div>
            </div>
               
        )
    }
}

export default Navbar