import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';


const ExpandableSection = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="expandable-section">
            <button className="expandable-button" onClick={toggleOpen}>
                {title} {isOpen ? '▲' : '▼'}
            </button>
            {isOpen && <div className="expandable-content">{children}</div>}
        </div>
    );
};

class PrivacyPolicy extends Component {
    render() {
        return (
            <div className='faq-grid-layout'>
               <Navbar />
               <div className='top-spacer' />
               <div className='banner'>
                    <div className='banner-container'>
                        <h1>Privacy Policy</h1>
                        
                    </div>
               </div>
               <div className='mainsection'>
                    <div className='content-container'>
                        <h1>PRIVACY POLICY</h1>
                        <p>This privacy policy, (“<strong>Privacy Policy</strong>”) and any other documents referred to in it, set out the basis on which Environmental and Social Research Solutions Limited collects and processes your personal data as a data controller when you use our website or services. </p>
                        <p>Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it. 
                        By visiting <Link to="/">www.essolutions.ai</Link>, (the “<strong>Site</strong>”) or using our services or apps, (“<strong>Services</strong>”) you are accepting and consenting to the practices described in this Privacy Policy. </p>
                        <p>Please note: </p>
                        <p>This Privacy Policy does not apply to any data you provide to us when we process personal data as your data processor i.e. where we process customer data within the cloud service we provide to you, as a business to business service provider.</p>
                        <p>The Site and Services are not intended for use by children and we do not knowingly collect information relating to children. Each user needs to confirm that they are over 18 to access our services. We acknowledge that unintentional collection of children's data may occur on rare occasions. In such cases, we will promptly take steps to delete any such information upon becoming aware of it.</p>
                    
                        <h2>DATA CONTROLLER</h2>
                        <p>For the purposes of EU and UK data protection laws and any applicable national implementing laws, regulations and secondary legislation relating to the processing of personal data (together “Data Protection Law”), the data controller is Environmental and Social Research Solutions Limited of Trident Park, Notabile Gardens, No. 2 - Level 3, Mdina Road, Zone 2, Central Business District, Birkirkara CBD 2010, Malta, (“<strong>we</strong>”, “<strong>us</strong>”, “<strong>our</strong>”).</p>
                        <p>As the data controller, we are responsible for the personal data that we collect from you and that we control, such as enquiries, search queries, IP addresses, and device identifiers. However, it's important to note that you are responsible as data controller within your own right, for any or any personal information you provide to us during your interactions with our services and products, particularly were we conduct any processing of such data on your behalf. </p>

                        <ExpandableSection title="OUR ROLE AS DATA PROCESSOR FOR PRODUCTS AND SERVICES">
                            <p>This section provides an overview of the products and services offered by us and details how each product handles personal data in its role as a Data Processor. As a Data Processor, we process data on behalf of our clients (the Data Controllers) in accordance with their instructions and applicable data protection laws.</p>
                            <h2>1. myESRA®</h2>
                            <p>
                                <i>Description:</i> myESRA® is E&S Solutions' AI-powered research assistant that securely accesses a comprehensive, preloaded knowledge base of thousands of Environment & Social (E&S) documents. It provides instant, accurate information without requiring users to upload or share their own data, ensuring data privacy and integrity.
                            </p>
                            <p><i>Data Processing Details:</i></p>
                            <ul>
                                <li>
                                    <strong style={{ marginRight: '10px' }}>Purpose of Processing:</strong>
                                    myESRA® processes personal and organizational data to facilitate E&S research tasks, including document searching, data extraction, and generating insights based on preloaded E&S documents.
                                </li>
                                <li>
                                    <strong style={{ marginRight: '10px' }}>Data Storage:</strong>
                                    All data processed by myESRA® is stored in secure, isolated Amazon S3 buckets on AWS, ensuring data segmentation and preventing unauthorized access.
                                </li>
                                <li>
                                    <strong style={{ marginRight: '10px' }}>Security Measures:</strong>
                                    We implement robust encryption, strict access controls, and host the Large Language Model (LLM) locally to ensure data does not leave your private system. Specifically:
                                    
                                    <ul>
                                        <p>
                                        <strong style={{ marginRight: '4px' }}>Encryption:</strong>
                                        Data is encrypted at rest using AES256 and in transit using Transport Layer Security (TLS).
                                        </p>
                                        <p>
                                        <strong style={{ marginRight: '4px' }}>Access Controls:</strong>
                                        Access to Personal Data is restricted based on role-based permissions, adhering to the principles of least privilege and need-to-know.
                                        </p>
                                        <p>
                                        <strong style={{ marginRight: '4px' }}>Technical Measures:</strong>
                                        We employ pseudonymization, regular security assessments, and intrusion detection systems to safeguard Personal Data.
                                        </p>
                                    </ul>
                                        
                                </li>
                                
                                <li>
                                    <strong style={{ marginRight: '10px' }}>Data Retention and Deletion:</strong>
                                    <ul>
                                        <p>
                                            <strong style={{ marginRight: '10px' }}>Retention Policy:</strong>
                                            Personal Data is retained for the duration of the Agreement unless otherwise specified by the Controller.
                                        </p>
                                        <p>
                                            <strong style={{ marginRight: '10px' }}>Deletion Process:</strong>
                                            Upon termination of the Agreement, Personal Data is either returned or securely deleted within 14 days, with all copies removed from our systems unless required by law to retain certain data.
                                        </p>
                                        <p>
                                            <strong style={{ marginRight: '10px' }}>Audit Logs:</strong>
                                            Admin actions are logged and retained for 90 days to monitor data access and ensure accountability.
                                        </p>
                                    </ul>
                                </li>
                                <li>
                                    <strong style={{ marginRight: '10px' }}>Data Processing Agreement (DPA):</strong>
                                    We maintain a DPA with each client using myESRA®, detailing the scope and nature of data processing to ensure GDPR compliance.
                                </li>
                                <li>
                                    <strong style={{ marginRight: '10px' }}>Assistance with GDPR Compliance:</strong>
                                    We support our clients in fulfilling their GDPR obligations, including handling data subject requests as directed by the client.
                                </li>
                            </ul>

                            <h2>2. privatESRA</h2>
                            <p>
                                <i>Description:</i> privatESRA is an enterprise-only chatbot service that offers a secure and private knowledge base for organizations, ensuring data confidentiality and integrity.
                            </p>
                            <p><i>Data Processing Details:</i></p>

                            <ul>
                                <li>
                                    <strong style={{ marginRight: '10px' }}>Purpose of Processing:</strong>
                                    privatESRA processes personal and organizational data to facilitate secure chatbot interactions, manage team-based knowledge bases, and support document management within enterprises.
                                </li>
                                <li>
                                    <strong style={{ marginRight: '10px' }}>Data Storage:</strong>
                                    All documents and data are stored in secure, isolated Amazon S3 buckets on AWS, ensuring data segmentation and preventing unauthorized access.
                                </li>
                                <li>
                                    <strong style={{ marginRight: '10px' }}>Security Measures:</strong>
                                    We implement robust encryption, strict access controls, and host the Large Language Model (LLM) locally to ensure data does not leave your private system.
                                </li>
                                <li>
                                    <strong style={{ marginRight: '10px' }}>Data Retention and Deletion:</strong>
                                    Deleted documents are retained for a 30-day recovery period before permanent deletion, ensuring compliance and data privacy.
                                </li>
                                <li>
                                    <strong style={{ marginRight: '10px' }}>Audit Logs:</strong>
                                    Admin actions are logged and retained for a specified duration to monitor data access and ensure accountability.
                                </li>
                                <li>
                                    <strong style={{ marginRight: '10px' }}>Data Processing Agreement (DPA):</strong>
                                    We maintain a DPA with each client using privatESRA, detailing the scope and nature of data processing to ensure GDPR compliance.
                                </li>
                                <li>
                                    <strong style={{ marginRight: '10px' }}>Assistance with GDPR Compliance:</strong>
                                    We support our clients in fulfilling their GDPR obligations, including handling data subject requests as directed by the client.
                                </li>
                                </ul>



                        </ExpandableSection>

                        <h2>LEGAL BASIS FOR PROCESSING</h2>
                        <p>We will only use your personal data when the law allows us to. The law requires us to have a legal basis for collecting and using your personal data. We rely on one or more of the following legal bases:</p>
                        <ul>
                            <li>
                                <strong>Performance of a contract with you:</strong>
                                <p>Where we need to perform the contract we are about to enter into or have entered into with you. When you sign up to use our services you provide your data to us and agree to our T&C.</p>
                            </li>
                            <li>
                                <strong>Legitimate interests:</strong>
                                <p>Where it is necessary for our legitimate business interests (or those of a third party) and your interests and fundamental rights do not override those interests. For example to prevent fraud and enable us to give you the best and most secure customer experience.</p>
                            </li>
                            <li>
                                <strong>Legal obligation:</strong>
                                <p>We may use your personal data where it is necessary for compliance with a legal obligation that we are subject to. We will identify the relevant legal obligation when we rely on this legal basis. We will update you if new obligations are identified via email prior to using your data.</p>
                            </li>
                            <li>
                                <strong>Consent:</strong>
                                <p>We rely on consent only where we have obtained your active agreement to use your personal data for a specified purpose, for example if you subscribe to an email newsletter. Consent can be withdrawn at any time by following the link to unsubscribe from our services.</p>
                            </li>
                        </ul>
                        <h2>PERSONAL DATA WE MAY COLLECT ABOUT YOU</h2>
                        <p>We may collect and process personal data about you. Personal data, or personally identifiable information, means any information about an individual from which that individual can be identified. We collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows:</p>
                        <ul>
                            <li>
                                <strong>Identity Data:</strong>
                                <p>includes first name, last name, user name or similar identifier.</p>
                            </li>
                            <li>
                                <strong>Contact Data:</strong>
                                <p>includes billing address, business address, email address and telephone numbers, business name.</p>
                            </li>
                            <li>
                                <strong>Financial Data:</strong>
                                <p>includes bank account and payment card details. This information is not stored by us but will be stored with Stripe. </p>
                            </li>
                            <li>
                                <strong>Transaction Data:</strong>
                                <p>includes details about payments to and from you and other details of products and services you have purchased from us.</p>
                            </li>
                            <li>
                                <strong>Technical Data:</strong>
                                <p>includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, device ID and other technology on the devices you use to access this Site or the Services. Our cookie policy explicitly states which cookies we use and we ask you to consent to it. </p>
                            </li>
                            <li>
                                <strong>Profile Data:</strong>
                                <p>includes your username and password, purchases or orders made by you, your interests, preferences, feedback and survey responses.</p>
                            </li>
                            <li>
                                <strong>Usage Data:</strong>
                                <p>includes information about how you use our Site and Services, including the full Uniform Resource Locators (URL) clickstream to, through and from our Site and Services (including date and time); products you viewed or searched for; page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), and methods used to browse away from the page and any phone number used to call our customer service number.</p>
                            </li>
                            <li>
                                <strong>Marketing and Communication Data: </strong>
                                <p>includes your preferences in receiving marketing from us and our third parties and your communication preferences.</p>
                            </li>
                            <li>
                                <strong>Location Data: </strong>
                                <p>includes GPS technology <strong>OR</strong> IP address used to determine your current location. Some of our location-enabled Services require your personal data for the feature to work. If you wish to use the particular feature, you will be asked to consent to your data being used for this purpose. You can withdraw your consent at any time by disabling Location Data in your settings.</p>
                            </li>
                            <li>
                                <strong>Aggregated Data: </strong>
                                <p>We also collect, use and share aggregated data such as statistical or demographic data. Aggregated Data could be derived from your personal data but is not considered personal data in law as this data will not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific website feature in order to analyse general trends in how users interact with the website. However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this Privacy Policy.</p>
                            </li>
                            <li>
                                <strong>Special Category Data:</strong>
                                <p>We do not collect, store and/or use special category data about you. This means information about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health, and genetic and biometric data.</p>
                            </li>
                            <li>
                                <strong>If you fail to provide personal data</strong>
                                <p>Where we need to collect personal data by law, or under the terms of a contract we have with you, and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you (for example, to provide you with goods or services). In this case, we may have to cancel a product or service you have with us but we will notify you if this is the case at the time.</p>
                            </li>
                        </ul>
                        <h2>HOW PERSONAL DATA IS COLLECTED</h2>
                        <p>We use different methods to collect data from and about you including via:</p>
                        <ul>
                            <li>
                                <strong>Direct Interactions:</strong>
                                <p>You may give us your Identity, Contact and Financial Data when you fill in forms or correspond with us by post, phone, email or otherwise. This includes personal data you provide when you register to use our Site or to receive our newsletter, subscribe to use our Services, create an account to use our Site or Services, request marketing to be sent to you, search for a product or place an order on our Site, participate in discussion boards or other social media functions on our Site, enter a competition, promotion or survey, attend a conference or webinar, give us feedback or contact us and when you report a problem with our Site or Services.</p>
                            </li>
                            <li>
                                <strong>Purchases: </strong>
                                <p>If you make purchases via our Site or within any Services, or register for an event or webinar, we may require you to provide your Identity, Contact, Financial and Transaction Data.</p>
                            </li>
                            <li>
                                <strong>Automated Technologies or Interactions:</strong>
                                <p>As you interact with our Services, Sites or emails, we automatically collect Technical Data about your device, browsing actions, patterns, Location Data and Usage Data. We collect this personal data by using cookies, server logs, web beacons, pixels, and other similar technologies about your device, and your use of our Site and Services. We may also receive Technical Data and Location Data about you if you visit other websites using our cookies. Please see the Cookie section below for further details.</p>
                            </li>
                        </ul>
                        <h2>PERSONAL DATA WE RECEIVE FROM OTHER SOURCES</h2>
                        <p>We work closely with the third parties set out in our Third Party Supplier List which includes, for example, business partners, sub-contractors in technical, payment and delivery services, advertising networks, analytics providers, search information providers, credit reference agencies and we may receive the following personal data about you from them:</p>
                        <ul>
                            <li>
                                <strong>Technical Data:</strong>
                                <p>from analytics providers, advertising networks and search information providers.</p>
                            </li>
                            <li>
                                <strong>Contact, Transaction and Financial Data:</strong>
                                <p>from providers of technical, payment and delivery services.</p>
                            </li>
                            <li>
                                <strong>Identity and Contact Data: </strong>
                                <p>from providers of chat/communication/helpdesk services with customers including via email.</p>
                            </li>
                            <li>
                                <strong>Email Communications and Contact Data:</strong>
                                <p>from providers of email communications service providers.</p>
                            </li>
                            <li>
                                <strong>Business Contact and Financial Data:</strong>
                                <p>from CRM service providers who manage contacts and keep a record of communications/ interactions with customers.</p>
                            </li>
                            <li>
                                <strong>Contact Data and Financial Data:</strong>
                                <p>from cloud accounting systems that store email and names of persons sent invoices by email.</p>
                            </li>
                        </ul>

                        <h2>COOKIES</h2>
                        <p>We use cookies or similar tracking technology, on our Site and in the Services to distinguish you from other users of our Site and Services. This helps us to provide you with a good experience when you browse our Site and also allows us to improve the Site and Services.</p>
                    
                        <h2>What are cookies</h2>
                        <p>Cookies are small text files that are placed on your computer by websites that you visit. They are widely used in order to make websites work, or work more efficiently, as well as to provide information to the owners of the website. Cookies can be “persistent” or “session” cookies.</p>
                        <p>We use persistent cookies and session cookies.</p>

                        <h2>Persistent Cookies</h2>
                        <p>A persistent cookie is stored on a user’s device in between browser sessions which allows the preferences or actions of a user across the Site (or in some cases across different websites) to be remembered. We use persistent cookies to save your login information for future logins to the Site or Services.</p>
                    
                        <h2>Session Cookies</h2>
                        <p>A session cookie allows the Site or Services to link your actions during a browser session. We use session cookies to enable certain features of the Site or Services, to better understand how you interact with the Site or Services and to monitor aggregate usage by users and web traffic routing on the Site. Unlike persistent cookies, session cookies are deleted from your computer when you log off from the Site or Services and then close your browser. </p>
                    
                        <h2>Which cookies we use and why</h2>
                        <ul>
                            <li>
                                <strong>Strictly necessary cookies:</strong>
                                <p>These are cookies that are required for the operation of our Site. They include, for example, cookies that enable you to log into secure areas of our Site and a cookie that records your cookie preferences.</p>
                            </li>
                            <li>
                                <strong>Analytics and tracking cookies:</strong>
                                <p>These cookies allow us to recognise and count the number of visitors and to see how visitors move around our Site when they are using it. This helps us to improve the way our Site and Services work, for example, by ensuring that users are finding what they are looking for easily.</p>
                            </li>
                            <li>
                                <strong>Functional cookies: </strong>
                                <p>These cookies are used to recognise you when you return to our Site. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region).</p>
                            </li>
                            <li>
                                <strong>Marketing cookies:</strong>
                                <p>These cookies record your visit to our Site, the pages you have visited and the links you have followed. We will use this information to make our Site and Services and the advertising displayed on them more relevant to your interests. We may also share this information with third parties for this purpose.</p>
                                <p>You can find more information about the individual cookies we use and the purposes for which we use them in the table below.</p>
                            </li>
                        </ul>
                        <table border="1" cellspacing="1" cellpadding="5">
                            <tr>
                                <th>Cookie</th>
                                <th>Type</th>
                                <th>Purpose</th>
                                <th>Duration</th>
                            </tr>
                            <tr>
                                <td>_ga</td>
                                <td>Analytics cookies</td>
                                <td>
                                    Google sets these third party tracking cookies to collect information about how visitors use our Site. We use the information to compile reports and to help us improve the Site. The cookies collect information in an anonymous form, including the number of visitors to the Site, where visitors have come to the Site from and the pages they visited. If you do not allow these cookies we will not be able to include your visit in our statistics. You can read the full Google Analytics privacy policy at: <a href="http://www.google.com/policies/privacy/" target="_blank">http://www.google.com/policies/privacy/</a>.
                                </td>
                                <td>2 years</td>
                            </tr>
                        </table>
                        <br />
                        <p>You can set up your browser options, to stop your computer accepting cookies or to prompt you before accepting a cookie from the websites you visit. If you do not accept cookies, however, you may not be able to use the whole of the Site or all functionality of the Services.</p>
                        <p>
                            To find out more about cookies, including how to see what cookies have been set and how to manage and delete them, visit <a href="https://www.allaboutcookies.org" target="_blank" rel="noopener noreferrer">www.allaboutcookies.org</a>. To opt out of being tracked by Google Analytics across all websites visit <a href="http://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">http://tools.google.com/dlpage/gaoptout</a>.
                        </p>

                        <h2>Pixel-Tags</h2>
                        <p>We may use "pixel tags," which are small graphic files that allow us to monitor the use of the Site and Services. A pixel tag can collect information such as the IP (Internet Protocol) address of the computer that downloaded the page on which the tag appears; the URL (Uniform Resource Locator) of the page on which the pixel tag appears; the time the page containing the pixel tag was viewed; the type of browser that fetched the pixel tag; and the identification number of any cookie on the computer previously placed by that server. When corresponding with you via HTML capable e-mail, we may use "format sensing" technology, which allows pixel tags to let us know whether you received and opened our e-mail.</p>
                    
                        <h2>Web Beacons</h2>
                        <p>Some of our web pages may contain electronic images known as web beacons (sometimes known as clear gifs) that allow us to count users who have visited these pages. Web beacons collect only limited information which includes a cookie number; time and date of a page view; and a description of the page on which the web beacon resides. We may also carry web beacons placed by third party advertisers. These web beacons do not carry any personal data and are only used to track the effectiveness of a particular campaign.</p>
                    
                        <h2>Do not track</h2>
                        <p>We do not support Do Not Track (“<strong>DNT</strong>”).</p>
                        <p>Do Not Track is a preference you can set in your web browser to inform websites that you do not want to be tracked. You can enable or disable Do Not Track by visiting the “Preferences” or “Settings” page of your web browser.</p>
                    
                        <h2>USES MADE OF YOUR PERSONAL DATA</h2>
                        <p>We have set out below, in a table format, a description of all the ways we plan to use your personal data, and which of the legal bases we rely on to do so. We have also identified what our legitimate interests are where appropriate.</p>
                        <p>Note that we may process your personal data for more than one lawful basis depending on the specific purpose for which we are using your data. Please contact us if you need details about the specific legal ground we are relying on to process your personal data where more than one ground has been set out in the table below.</p>
                    
                        <table>
                            <tr>
                                <th>Purpose/Activity</th>
                                <th>Type of data</th>
                                <th>Lawful basis for processing</th>
                            </tr>
                            <tr>
                                <td>To register you as a new customer</td>
                                <td>Identity Data, Contact Data</td>
                                <td>Performance of a contract with you</td>
                            </tr>
                            <tr>
                                <td>To process and deliver your order including:<ul><li>Manage payments, fees and charges</li><li>Collect and recover money owed to us</li></ul></td>
                                <td>Identity Data, Contact Data, Financial Data, Transaction Data, Marketing and Communications Data</td>
                                <td><ol><li>Performance of a contract with you</li><li>Necessary for our legitimate interests (to recover debts due to us)</li></ol></td>
                            </tr>
                            <tr>
                                <td>To manage our relationship with you which will include:<ul><li>Notifying you about changes to our terms, this Privacy Policy, the Site or Services</li><li>Dealing with your requests, complaints and queries</li></ul></td>
                                <td>Identity Data, Contact Data, Profile Data, Marketing and Communications Data</td>
                                <td><ol><li>Performance of a contract with you</li><li>Necessary to comply with a legal obligation</li><li>Necessary for our legitimate interests (to keep our records updated, to manage our relationship with you and to study how customers use our products/services)</li></ol></td>
                            </tr>
                            <tr>
                                <td>To enable you to partake in a prize draw, competition, review or complete a survey</td>
                                <td>Identity Data, Contact Data, Profile Data, Usage Data, Marketing and Communications Data</td>
                                <td><ol><li>Performance of a contract with you and consent obtained from you</li><li>Necessary for our legitimate interests (to study how customers use our products/services, to develop them and grow our business)</li></ol></td>
                            </tr>
                            <tr>
                                <td>To administer and protect our business and this Site (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data)</td>
                                <td>Identity Data, Contact Data, Technical Data</td>
                                <td>
                                    <ul>
                                        <li>(a) Necessary for our legitimate interests (for running our business, provision of administration and IT services, network security, to prevent fraud and in the context of a business reorganisation or group restructuring exercise)</li>
                                        <li>(b) Necessary to comply with a legal obligation</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td>To deliver relevant Site and Services content and online advertisements to you and measure or understand the effectiveness of the advertising we serve to you</td>
                                <td>Identity Data, Contact Data, Profile Data, Usage Data, Marketing and Communications Data, Technical Data</td>
                                <td>Consent, having obtained your prior consent to receiving direct marketing communications</td>
                            </tr>
                            <tr>
                                <td>To use data analytics to improve our Site and Services, customer relationships and experiences and to measure the effectiveness of our communications and marketing</td>
                                <td>Technical Data, Usage Data</td>
                                <td>Necessary for our legitimate interests (to define types of customers for our products and services, to keep our website updated and relevant, to develop our business and to inform our marketing strategy)</td>
                            </tr>
                            <tr>
                                <td>To send you relevant marketing communications and make personalised suggestions and recommendations to you about goods or services that may be of interest to you</td>
                                <td>Identity Data, Contact Data, Technical Data, Usage Data, Profile Data, Marketing and Communications Data</td>
                                <td>Necessary for our legitimate interests (to carry out direct marketing, develop our Site and Services and grow our business)</td>
                            </tr>
                            <tr>
                                <td>To enable you to apply to work for us</td>
                                <td>Identity Data, Contact Data, Financial Data, Technical Data</td>
                                <td>
                                    <ul>
                                        <li>(a) Performance of a contract with you</li>
                                        <li>(b) Necessary to comply with a legal obligation</li>
                                        <li>(c) Necessary for our legitimate interests (to keep our records updated and to enable you to apply to work for us)</li>
                                    </ul>
                                </td>
                            </tr>
                        </table>
                        <br />
                        <p>We will not sell or rent your personal data to anyone.</p>
                        <p>We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you wish to obtain an explanation as to how the processing for the new purpose is compatible with the original purpose, please contact us.</p>
                        <p>Please note that we may process your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.</p>

                        <h2>DISCLOSURE OF YOUR PERSONAL DATA</h2>
                        <p><strong>Personal data we share with third parties.</strong> We may share your personal data where necessary with the third parties set out in our Third Party Supplier List for the purposes set out in the table above. Below is a summary of the types of third parties used:</p>
                        <ul>
                            <li>
                                <p>Any member of our group, which means our subsidiaries, our ultimate holding company and its subsidiaries.</p>
                            </li>
                            <li>
                                <p>Business partners, suppliers and sub-contractors for the performance of any contract we enter into with them or you to provide services such as IT and system administration services, email communications, hosting services, backup services, credit card processing, research, development, marketing and customer support.</p>
                            </li>
                            <li>
                                <p>Analytics and search engine providers that assist us in the improvement and optimisation of our Site and Services.</p>
                            </li>
                            <li>
                                <p>Professional advisors acting as service providers to us in relation to the Site or Services - including lawyers, bankers, auditors, and insurers who provide consultancy, banking, legal, insurance and accounting services.</p>
                            </li>
                            <li>
                                <p>Tax authorities, regulators and other authorities who require reporting of processing activities in certain circumstances.</p>
                            </li>
                            <li>
                                <p>Advertisers and advertising networks that require the data to select and serve relevant adverts to you and others. We do not disclose personal data about identifiable individuals to our advertisers, but we may provide them with Aggregated Data about our users. We may also use such Aggregated Data to help advertisers reach the kind of audience they want to target (for example, women living in London). We may make use of the personal data we have collected from you to enable us to comply with our advertisers' wishes by displaying their advertisement to that target audience.</p>
                            </li>
                            <li>
                                <p>Credit reference agencies for the purpose of assessing your credit score where this is a condition of us entering into a contract with you.</p>
                            </li>
                        </ul>
                        <p><strong>Personal data we disclose to third parties.</strong> We may disclose your personal data where necessary to third parties:</p>
                        <ul>
                            <li>
                                <p>In the event that we sell or buy any business or assets, in which case we may disclose your personal data to the prospective seller or buyer of such business or assets.</p>
                            </li>
                            <li>
                                <p>If we or a member of our group of companies or substantially all of their assets are acquired by a third party, in which case personal data held by them about their customers will be one of the transferred assets.</p>
                            </li>
                            <li>
                                <p>If we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our terms and conditions, terms of use and/or any other legal agreements; or to protect our rights, property, safety, our customers or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection and credit risk reduction.</p>
                            </li>
                            <li>
                                <p>Where it is included in Aggregated Data we use in the normal course of operating our business; for example, sharing Aggregated Data with other Site or Services users, our customers or publicly to show trends or benchmark the general use of our Site and Services. </p>
                            </li>
                        </ul>
                        <p>We require all third parties to respect the security of your personal data and to treat it in accordance with applicable law. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.</p>
                    
                        <h2>INTERNATIONAL TRANSFERS</h2>
                        <p>Our Services are global and your personal data may be stored and processed in any country where we have operations or our staff are located.</p>
                        <p>We may transfer your personal data to service providers that carry out certain functions on our behalf. This may involve transferring personal data outside your country of residence to countries which have laws that do not provide the same level of data protection as your country of residence. When we share your personal data with our service providers, who are identified in our Third Party Supplier List, this will involve transferring your personal data to the country set out in the Third Party Supplier List for each service provider.</p>
                    
                        <h2>European Data</h2>
                        <p>When we transfer UK, EU or Swiss personal data to countries whose laws do not provide the same level of data protection as the UK, the EU or Switzerland, we always ensure that a similar degree of protection is afforded to your data by ensuring that one of the following applicable safeguards is in place:</p>
                        <ul>
                            <li>
                                <p>We will only transfer UK personal data outside of the UK to: (i) countries deemed by the ICO to provide an adequate level of protection for UK personal data; or (ii) entities located outside of the UK with whom standard contractual terms approved for use in the UK which give the transferred personal data the same protection as it has in the UK have been entered into, for example the International Data Transfer Addendum, (IDTA) to the European Commission’s standard contractual clauses for international data transfers or binding corporate rules (BCRs); or (iii) entities located in the USA certified under the UK Extension to the EU-U.S. DPF; or (iv) any entity located outside of the UK that is subject to any other transfer mechanism, bespoke contract, approved code of conduct or certification scheme approved by the ICO.</p>
                            </li>
                            <li>
                                <p>We will only transfer EU personal data outside of the EEA to: (i) countries deemed by the European Commission to provide an adequate level of protection for EU personal data; or (ii) entities located outside of the EEA with whom standard contractual terms approved for use in the EU which give the transferred personal data the same protection as it has in the EU have been entered into, for example the European Commission’s standard contractual clauses for international data transfers, (EU SCCs) or binding corporate rules (BCRs); or (iii) entities located in the USA certified under the EU-U.S. DPF; or (iv) any entity located outside of the EEA that is subject to any other transfer mechanism, bespoke contract, approved code of conduct or certification scheme approved by the European Commission.</p>
                            </li>
                            <li>
                                <p>We will only transfer Swiss personal data outside of Switzerland to: (i) countries deemed by the Swiss Data Protection Authority to provide an adequate level of protection for Swiss personal data; or (ii) entities located outside of Switzerland with whom standard contractual terms approved for use in Switzerland which give the transferred personal data the same protection as it has in Switzerland have been entered into, for example the European Commission’s standard contractual clauses for international data transfers, (EU SCCs) or binding corporate rules (BCRs); or (iii) entities located in the USA certified under the Swiss-U.S. DPF; or (iv) any entity located outside of Switzerland that is subject to any other transfer mechanism, bespoke contract, approved code of conduct or certification scheme approved by the Swiss Data Protection Authority. </p>
                            </li>
                            <li>
                                <p>To obtain a copy of any of the above these contractual safeguards, please contact us as set out at the end of this Privacy Policy.</p>
                            </li>
                        </ul>
                        <p>Please note that our Site and Services are accessible via the internet and may potentially be accessed by anyone around the world. Other users may access the Site or Services from outside the EEA, Switzerland or the UK. This means that where you chose to post your personal data on our Site or within the Services, it could be accessed from anywhere around the world and therefore a transfer of your personal data outside of the EEA, Switzerland or the UK may be deemed to have occurred.</p>
                    
                        <h2>DATA SECURITY</h2>
                        <p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. For example, all information you provide to us is stored on our secure servers. Any credit card information or payment transactions will be encrypted using SSL technology.</p>
                        <p>Where we have given you (or where you have chosen) a password which enables you to access certain parts of the Site or Services, you are responsible for keeping this password confidential. We ask you not to share any password with anyone. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.</p>
                        <p>We have put in place procedures to deal with any personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so. We will notify you of any data breach within 7 days of the incident via email you provided to us during the registration process. </p>
                        <p>Unfortunately, the transmission of information via the Internet is not completely secure. Although we will endeavour to protect your personal data, we cannot guarantee the security of your personal data transmitted to our Site or the Services. Any transmission is at your own risk. Once we have received your personal data, we will use strict procedures and security features to try to prevent unauthorised access.</p>
                    
                        <h2>THIRD PARTY LINKS</h2>
                        <p>Our Site and Services may, from time to time, contain links to and from third party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. Please note that these third party websites, plug-ins and applications have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any personal data to these third party websites, plug-ins or applications.</p>
                    
                        <h2>DATA RETENTION</h2>
                        <p>We will only retain your personal data for as long as reasonably necessary to fulfil the purposes we collected if for, including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements. We may retain your personal data for a longer period in the event of a complaint, if we reasonably believe there is a prospect of litigation in respect of our relationship with you, to comply with law enforcement requests, maintain security, prevent fraud and abuse, resolve disputes, enforce our legal agreements, or fulfil your request to “unsubscribe” from further messages from us. </p>
                        <p>To determine the appropriate retention period for personal data, we consider the amount, nature and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal, regulatory, tax, accounting or other requirements. Usually this will be for as long as we provide access to the Site or Services to you, your account with us remains open or any period set out in any relevant contract you have with us</p>
                        <p>By law we have to keep basic information about our customers (including Contact Data, Identity Data, Financial Data and Transaction Data) for 6 years after they cease being customers for tax purposes.</p>
                        <p>In some circumstances we will anonymise your personal data (so that it can no longer be associated with your) after your account has been closed and we may use this for research or statistical purposes, in which case we may use this information indefinitely without further notice to you. </p>
                        <p>Please note: After you have closed your account or deleted information from your account, any information you have shared with others will remain visible. We do not control data that other users may have copied from the Site or Services. Your profile may continue to be displayed in the services of others (e.g. search engine results) until they refresh their cache.</p>
                    
                        <h2>YOUR LEGAL RIGHTS</h2>
                        <p>You have a number of rights under Data Protection Law in relation to your personal data. You have the right to:</p>
                        <ul>
                            <li>
                                <p>Request access to your personal data (commonly known as a “subject access request”). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it. </p>
                            </li>
                            <li>
                                <p>Request rectification of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.</p>
                            </li>
                            <li>
                                <p>Request erasure of your personal data in certain circumstances. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.</p>
                            </li>
                            <li>
                                <p>Request restriction of the processing of your personal data. This enables you to ask us to suspend the processing of your personal data in one of the following scenarios: (i) if you want us to establish the data’s accuracy; (ii) where our use of the data is unlawful but you do not want us to erase it; (iii) where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or (iv) you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.</p>
                            </li>
                            <li>
                                <p>Object to the processing of your personal data where we are relying on a legitimate interest (or those of a third party) as the legal basis for that particular use of your data (including carrying out profiling based on our legitimate interests). In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your right to object.</p>
                            </li>
                            <li>
                                <p>Request the transfer of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.</p>
                            </li>
                            <li>
                                <p>Object any time to the processing of your personal data for direct marketing purposes.</p>
                            </li>
                            <li>
                                <p>Withdraw consent at any time where we are relying on consent to process your personal data as the legal basis for using your data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.</p>
                            </li>
                        </ul>
                        <p>If you wish to exercise any of the above rights, please contact us as set out at the end of this Privacy Policy.</p>
                        <p>You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we could refuse to comply with your request in these circumstances.</p>
                        <p>We will try to respond to all legitimate requests within 30 days and will deal with requests we receive from you, in accordance with the provisions of Data Protection Law. Occasionally it could take us longer, if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</p>
                        <p>We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.</p>
                    
                        <h2>MARKETING COMMUNICATIONS</h2>
                        <p>We may use your Identity, Contact, Technical, Usage and Profile Data to form a view on which products, services and offers may be of interest to you so that we can send you relevant marketing communications.</p>
                        <ul>
                            <li>
                                <p>
                                    <strong>Direct marketing:</strong>
                                    You will receive marketing communications if you “opt in” to receive marketing communications from us when you registered on our Site or within the Services, or if you enquired about, or have purchased any of our goods or services and you have not opted out of receiving such marketing. You have the option to opt out any time by unsubscribing from marketing emails. 
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>Third Party Marketing:</strong>
                                    We will obtain your express opt-in consent before we share your personal data with any third party for their own direct marketing purposes.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>Opting out of Marketing:</strong>
                                    You can ask us to stop sending you marketing communications at any time by logging into the Site or Services and unchecking the relevant boxes to adjust your marketing preferences or by following the “opt out” or “unsubscribe” links within any marketing communication sent to you.
                                </p>
                                <p>Once you “opt out” or “unsubscribe”, you will no longer receive any marketing communications from us. You will however still received service related communications that are essential for administrative or customer service purposes, for example relating to orders, billing, updates, checking that your contact details are up to date and support issues.</p>
                                <p>Please note that where we send push notifications from time to time in order to update you about any service updates, events and promotions we may be running, if you no longer wish to receive these communications, please disable these in the settings on your device.</p>
                            </li>
                        </ul>

                        <h2>COMPLAINTS</h2>
                        <p>Our intention is to meet the highest standards when collecting and using personal data. For this reason, we take complaints we receive very seriously. We encourage users to notify us if they think that our collection or use of personal data is unfair, misleading or inappropriate. If you have any complaints about our use of your personal data, please contact us as set out at the end of this Privacy Policy or you have the right to make a complaint to your local data protection supervisory authority.</p>
                        <p>For EU individuals: </p>
                        <p>Office of the Information and Data Protection Commissioner, Floor 2, Airways House,
                        Triq Il-Kbira, Tas-Sliema SLM 1549, Malta</p>
                        <p>+356 2328 7100</p>
                        <p>idpc.info@idpc.org.mt</p>
                        <br />
                        <p>Our UK representative is: </p>
                        <p>Laura Palmariello, InfoSafe Data Protection & IG Solutions, 10 Thorncots Road, Hatch, Sandy, SG19 1PU. If you wish to raise a question please contact laura@info-safe.co.uk, quoting “Environmental & Social Research Solutions Limited” in the subject line.</p>
                    
                        <h2>AGE OF USERS</h2>
                        <p>This Site and the Services are not intended for and shall not be used by anyone under the age of 18. Each user needs to confirm that they are over 18 to access our services.</p>
                    
                        <h2>CHANGES TO OUR PRIVACY POLICY</h2>
                        <p>We keep our Privacy Policy under regular review. Any changes we may make to our Privacy Policy in the future will be posted on this page and, where appropriate, notified to you by email. Please check back frequently to see any updates or changes to our Privacy Policy.</p>
                        <p>This Privacy Policy was last updated on 21 January 2025 and this version replaces any other Privacy Policy previously applicable from this date.</p>
                    
                        <h2>YOUR DUTY TO INFORM US OF CHANGES</h2>
                        <p>It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us, for example a new address or email address. We might send you annual reminders to verify and update your information. </p>
                    
                        <h2>CONTACT</h2>
                        <p>If you have any questions, comments or requests regarding our privacy practices or about this Privacy Policy or you wish to exercise any of your privacy rights, please contact us as follows.</p>
                        <p>By post: Environmental and Social Research Solutions Limited, Trident Park, Notabile Gardens, No. 2 - Level 3, Mdina Road, Zone 2, Central Business District, Birkirkara CBD 2010, Malta</p>
                        <p>By email: info@essolutions.ai</p>

                        <h2>Third Party Supplier List</h2>
                        <table>
                            <tr>
                                <th>Third Party</th>
                                <th>Service</th>
                                <th>Privacy Policy</th>
                            </tr>
                            <tr>
                                <td>Amazon Web Services Inc.</td>
                                <td>Cloud hosting services</td>
                                <td><a href="https://aws.amazon.com/privacy/" target="_blank" rel="noopener noreferrer">https://aws.amazon.com/privacy/</a></td>
                            </tr>
                            <tr>
                                <td>Google Inc.</td>
                                <td>Website analytics services</td>
                                <td><a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">https://policies.google.com/privacy</a></td>
                            </tr>
                            <tr>
                                <td>GoDaddy Operating Company, LLC.</td>
                                <td>Email delivery services and website hosting</td>
                                <td><a href="https://www.godaddy.com/en-uk/legal/agreements/privacy-policy" target="_blank" rel="noopener noreferrer">https://www.godaddy.com/en-uk/legal/agreements/privacy-policy</a></td>
                            </tr>
                            <tr>
                                <td>Stripe Inc</td>
                                <td>Payment processing services</td>
                                <td><a href="https://stripe.com/gb/privacy" target="_blank" rel="noopener noreferrer">https://stripe.com/gb/privacy</a></td>
                            </tr>
                            <tr>
                                <td>Pinecone Systems Inc</td>
                                <td>Vector database services</td>
                                <td><a href="https://pinecone.io/privacy" target="_blank" rel="noopener noreferrer">https://pinecone.io/privacy</a></td>
                            </tr>
                            <tr>
                                <td>OpenAI OpCo LLC.</td>
                                <td>Support chat and communication services</td>
                                <td><a href="https://openai.com/policies/privacy-policy" target="_blank" rel="noopener noreferrer">https://openai.com/policies/privacy-policy</a></td>
                            </tr>
                        </table>

                    </div>
               </div>
               <Footer />
            </div>
        )
    }
}

export default PrivacyPolicy