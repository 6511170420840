import React, { Component } from 'react';
import { connect } from 'react-redux';
import { auth } from '../Authentication/firebase/firebase';
import { withRouter, Link } from 'react-router-dom';
import API_Root from '../Constants/API_Root';
import { toggleToast } from "../Actions/ToggleToast";
import '../private-chatbot.css'
import ReactDOM from 'react-dom';

class PrivateChatbot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: '',
            messages: [],
            isLoading: false,
            threadId: null,
            isSidebarOpen: true,
            darkMode: false,
            isLoggedIn: false,
            userEmail: "",
            selectedFiles: [],
            filenames: [],
            pocAccess: false,
            generatedUserId: null,
            session_id: null,
            sessions: [],
            isOwner: false,
            instanceExists: false,
            isAdmin: false,
            groups: [],
            showTeamMembers: false,
            isModalVisible: false,
            modalPosition: { x: 0, y: 0 },
            ingestionJobStatus: null,
            isAdminModalOpen: false,
            showTeamInput: false,
            newTeamName: '',
            teams: [],
            isInviteModalOpen: false,
            selectedTeam: null,
            organizationEmails: [],
            selectedInvites: [],
            selectedRoles: {}, 
            teamMembers: {},
            filesForApproval: [],
            isEditTeamModalOpen: false,
            selectedTeamForEdit: null,
            editTeamMembers: [],
            selectedModel: "llama38b",
            ingestionJobId: "",
            firstName: "",
            lastName: "",
            isFeedbackModalVisible: false,
            feedbackMessage: "",
            feedbackTargetMessage: "",
            modalPosition: { x: 0, y: 0 },
            feedbackInputs: {}, 
            feedbackStatus: {},
            isSmallModalVisible: false,
            selectedSessionId: null,
            isRenaming: false, 
            newSessionName: "",
        };
    }

    componentDidMount() {
        const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';
        if (!isLoggedIn) {
            this.props.history.push('/login');
        }
        this.setState({ isLoggedIn });
    
        auth.onAuthStateChanged(user => {
            if (user) {
                this.setState(
                    {
                        isLoggedIn: true,
                        userEmail: user.email,
                    },
                    () => {
                        // Now that userEmail is set, call the functions that depend on it
                        this.fetchUserRole(user.email);
                        this.fetchProfileInformation(user.email); // use user.email directly
                        this.fetchDemoSessions();
                        this.fetchTeamMembers();
                        this.fetchDemoFiles();  // Moved here, so userEmail is set
                    }
                );
            } else {
                this.setState({
                    isLoggedIn: false,
                    userEmail: null,
                });
            }
        });
        this.fetchOrganizationEmails();
        document.addEventListener("click", this.handleClickOutside);
    }
    

    componentWillUnmount() {
        document.removeEventListener("click", this.handleClickOutside);
    }

    openFeedbackModal = (message, event) => {
        console.log("Opening feedback modal with message:", message); // Add debug log
        const rect = event.target.getBoundingClientRect();
        this.setState({
            isFeedbackModalVisible: true,
            feedbackTargetMessage: message, // Ensure message contains `id` and `feedback_status`
            modalPosition: {
                x: rect.left + window.scrollX,
                y: rect.top + window.scrollY - 40,
            },
        });
    };
    
    
    
    closeFeedbackModal = () => {
        this.setState({ isFeedbackModalVisible: false, feedbackMessage: "", feedbackTargetMessage: "" });
    };
    
    openSmallModal = (sessionId) => {
        this.setState({
            isSmallModalVisible: true,
            selectedSessionId: sessionId,
        });
    };
    
    
    closeSmallModal = () => {
        this.setState({ isSmallModalVisible: false });
    };
    
    

    fetchFilesForApproval = () => {
        fetch(API_Root + '/get-unapproved-files', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                this.setState({ filesForApproval: data.files || [] });
            })
            .catch(error => {
                console.error('Error fetching files for approval:', error);
                this.props.toggleToast({
                    show: true,
                    type: "error",
                    message: "Failed to fetch files for approval.",
                });
            });
    };
    
    

    openEditTeamModal = (teamName) => {
        this.setState({ selectedTeamForEdit: teamName, isEditTeamModalOpen: true }, this.fetchTeamMembersForEdit);
    };

    fetchTeamMembersForEdit = () => {
        const { selectedTeamForEdit } = this.state;
    
        fetch(API_Root + '/get-team-members-by-name', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ team_name: selectedTeamForEdit }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                this.setState({ editTeamMembers: data.members || [] });
            })
            .catch(error => {
                console.error('Error fetching team members:', error);
            });
    };

    removeTeamMember = (email) => {
        const { selectedTeamForEdit } = this.state;
    
        fetch(API_Root + '/remove-team-member', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ team_name: selectedTeamForEdit, email }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(() => {
                this.setState((prevState) => ({
                    editTeamMembers: prevState.editTeamMembers.filter(member => member.email !== email),
                }));
                this.props.toggleToast({
                    show: true,
                    type: 'success',
                    message: `${email} removed from the team.`,
                });
            })
            .catch(error => {
                console.error('Error removing team member:', error);
                this.props.toggleToast({
                    show: true,
                    type: 'error',
                    message: 'Failed to remove team member.',
                });
            });
    };
    
    closeEditTeamModal = () => {
        
        this.setState({ isEditTeamModalOpen: false, selectedTeamForEdit: null, editTeamMembers: [] });
        this.fetchTeamMembers();
    };

    fetchUserRole = (email) => {
        fetch(API_Root + '/get-user-role', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => {
                this.setState({ isAdmin: data.role === 'admin' });
            })
            .catch((error) => {
                console.error('Error fetching user role:', error);
            });
    };
    
    submitFeedback = () => {
        const { feedbackMessage, feedbackTargetMessage, session_id } = this.state;
    
        if (!feedbackTargetMessage || !session_id) {
            this.props.toggleToast({
                show: true,
                type: "error",
                message: "Invalid feedback submission.",
            });
            return;
        }
    
        const { id: message_id, feedback_status } = feedbackTargetMessage; // Extract ID and feedback status
    
        fetch(`${API_Root}/demo-submit-feedback`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                session_id,
                message_id,
                feedback_status, // True (Thumbs Up) or False (Thumbs Down)
                feedback_msg: feedbackMessage || "", // Optional comment
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(() => {
                this.props.toggleToast({
                    show: true,
                    type: "success",
                    message: "Feedback submitted successfully.",
                });
                this.setState({
                    isFeedbackModalVisible: false,
                    feedbackMessage: "",
                    feedbackTargetMessage: "",
                });
            })
            .catch((error) => {
                console.error("Error submitting feedback:", error);
                this.props.toggleToast({
                    show: true,
                    type: "error",
                    message: "Failed to submit feedback. Please try again.",
                });
            });
    };
    
    
     

    fetchTeamMembers = () => {
        const { userEmail } = this.state;
    
        fetch(API_Root + '/get-team-members', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: userEmail }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                const { team_id, members } = data;
                this.setState({
                    selectedTeamId: team_id,
                    teamMembers: members, // Updated structure
                });
            })
            .catch(error => {
                console.error('Error fetching team members:', error);
            });
    };
    
    

    fetchOrganizationEmails = () => {
        fetch(API_Root + '/get-organization-users', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ organization_id: 1 }), // Pass organization_id dynamically if needed
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => {
                const emails = data.users; // No need to map `user.email` since backend is returning a list of usernames
                this.setState({ organizationEmails: emails });
            })
            .catch((error) => {
                console.error('Error fetching organization emails:', error);
                this.props.toggleToast({
                    show: true,
                    type: 'error',
                    message: 'Failed to fetch organization emails.',
                });
            });
    };    

    fetchDemoFiles = () => {
        const { userEmail } = this.state;

        fetch(`${API_Root}/get-demo-files`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: userEmail })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                this.setState({ filenames: data.filenames || [] });
            })
            .catch(error => {
                console.error('Error fetching demo files:', error);
                this.props.toggleToast({
                    show: true,
                    type: "error",
                    message: "Failed to fetch files from the database.",
                });
            });
        
    };

    removeFileFromApproval = (index) => {
        this.setState((prevState) => ({
            filesForApproval: prevState.filesForApproval.filter((_, i) => i !== index),
        }), () => {
            this.props.toggleToast({ show: true, type: "success", message: "File removed from the approval list." });
        });
    };
    
    

    approveFile = (fileId) => {
        fetch(API_Root + '/approve-file', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: fileId }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(() => {
                this.setState(prevState => ({
                    filesForApproval: prevState.filesForApproval.filter(file => file.id !== fileId),
                }));
                this.props.toggleToast({
                    show: true,
                    type: "success",
                    message: "File approved successfully!",
                });
            })
            .catch(error => {
                console.error('Error approving file:', error);
                this.props.toggleToast({
                    show: true,
                    type: "error",
                    message: "Failed to approve the file.",
                });
            });
    };

    addTeam = () => {
        const { newTeamName } = this.state;
    
        if (newTeamName.trim() === '') {
            this.props.toggleToast({
                show: true,
                type: "warning",
                message: "Please enter a valid team name.",
            });
            return;
        }
    
        fetch(API_Root + '/create-demo-team', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ team_name: newTeamName }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                this.setState(prevState => ({
                    teams: [...prevState.teams, newTeamName],
                    newTeamName: '', 
                    showTeamInput: false, 
                }), () => {
                    this.props.toggleToast({
                        show: true,
                        type: "success",
                        message: "Team added successfully!",
                    });
                });
            })
            .catch(error => {
                console.error('Error creating team:', error);
                this.props.toggleToast({
                    show: true,
                    type: "error",
                    message: "Failed to add team.",
                });
            });
    };
    
    fetchDemoTeams = () => {
        fetch(API_Root + '/get-demo-teams', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                this.setState({ teams: data.teams || [] });
            })
            .catch(error => {
                console.error('Error fetching demo teams:', error);
                this.props.toggleToast({
                    show: true,
                    type: 'error',
                    message: 'Failed to fetch teams.',
                });
            });
    };    

    openAdminModal = () => {
        this.fetchDemoTeams(); // Fetch the teams when the modal opens
        this.fetchFilesForApproval(); 
        this.setState({ isAdminModalOpen: true });
    };    

    handleInviteToTeam = (team) => {
        this.setState({ isInviteModalOpen: true, selectedTeam: team });
    };
    
    closeInviteModal = () => {
        this.setState({ isInviteModalOpen: false, selectedTeam: null, selectedInvites: [], selectedRoles: {} });
    };
    
    handleSelectInvite = (email, isChecked) => {
        this.setState((prevState) => {
            const selectedInvites = isChecked
                ? [...prevState.selectedInvites, email]
                : prevState.selectedInvites.filter((e) => e !== email);
            return { selectedInvites };
        });
    };
    
    handleRoleChange = (email, role) => {
        this.setState((prevState) => ({
            selectedRoles: { ...prevState.selectedRoles, [email]: role },
        }));
    };
    
    handleSendInvites = () => {
        const { selectedInvites, selectedRoles, selectedTeam } = this.state;
    
        if (selectedInvites.length === 0) {
            this.props.toggleToast({ show: true, type: "warning", message: "Please select at least one user to assign." });
            return;
        }
    
        const invites = selectedInvites.map(email => ({
            email,
            role: selectedRoles[email] || "user",
        }));
    
        fetch(API_Root + '/invite-team-members', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                team_name: selectedTeam,
                invites,
            }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                this.props.toggleToast({
                    show: true,
                    type: "success",
                    message: `${selectedInvites.length} members assigned to ${selectedTeam}!`,
                });
                this.closeInviteModal();
            })
            .catch(error => {
                console.error('Error inviting team members:', error);
                this.props.toggleToast({
                    show: true,
                    type: "error",
                    message: "Failed to assign members to the team.",
                });
            });
    };
    
    
    closeAdminModal = () => {
        this.setState({ isAdminModalOpen: false });
    };

    toggleSidebar = () => {
        this.setState(prevState => ({
            isSidebarOpen: !prevState.isSidebarOpen,
            showTeamMembers: prevState.isSidebarOpen ? false : prevState.showTeamMembers
        }), () => {
            this.forceUpdate();
        });
    }

    handleContextMenu = (event, sessionId) => {
        event.preventDefault();
        
        const menuWidth = 200; // Adjust based on the expected width of your menu
        const menuHeight = 500; // Adjust based on the expected height of your menu
    
        let x = event.clientX;
        let y = event.clientY;
    
        // Adjust for any potential offsets
        const offsetX = 0;
        const offsetY = -50;
    
        // Check if the menu would overflow the right edge of the viewport
        if (x + menuWidth > window.innerWidth) {
            x = window.innerWidth - menuWidth;
        }
    
        // Check if the menu would overflow the bottom edge of the viewport
        if (y + menuHeight > window.innerHeight) {
            y = window.innerHeight - menuHeight;
        }
    
        this.setState({
            isModalVisible: true,
            modalPosition: { x: x + offsetX, y: y + offsetY },
            selectedSessionId: sessionId
        });
    };
    
    closeModal = () => {
        this.setState({
            isModalVisible: false,
            modalPosition: { x: 0, y: 0 },
            selectedSessionId: null
        });
    };

    checkIngestionJobStatus = (email) => {
        fetch(API_Root + 'get-ingestion-job-status', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            console.log('Ingestion Job Status:', data);
    
            const status = data.ingestionJob?.status;
    
            if (status === 'IN_PROGRESS') {
                this.props.toggleToast({ show: true, type: "info", message: "The chatbot is preparing a new environment. Please try again at a later time." });
            } else {
                this.setState({ ingestionJobStatus: data });
            }
        })
        .catch(error => {
            console.error('Error checking ingestion job status:', error);
        });
    }

    checkOwnership = (email) => {
        fetch(API_Root + 'check-ownership', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                this.setState({ isOwner: data.isOwner, instanceExists: data.instanceExists });
            })
            .catch(error => {
                console.error('Error checking ownership:', error);
            });
    }

    checkAdminRole = (email) => {
        fetch(API_Root + 'check-admin-role', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                this.setState({ isAdmin: data.isAdmin });
            })
            .catch(error => {
                console.error('Error checking admin role:', error);
            });
    }

    createInstance = () => {
        const { userEmail } = this.state;

        this.setState({ isLoading: true });

        fetch(API_Root + 'create-private-instance', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userEmail }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                this.setState({ isLoading: false });
                this.props.toggleToast({ show: true, type: "success", message: "Private instance created successfully" });
                this.checkOwnership(userEmail); // Refresh ownership and instance status
            })
            .catch(error => {
                console.error('Error creating instance:', error);
                this.setState({ isLoading: false });
                this.props.toggleToast({ show: true, type: "error", message: "Error creating private instance." });
            });
    }

    fetchProfileInformation = (email) => {
        fetch(API_Root + 'get-profile-information', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
                ip_address: this.state.ip_address,
            }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                this.setState({
                    firstName: data.first_name || 'None',
                    lastName: data.last_name || 'None',
                    pocAccess: data.access || false
                }, () => {
                    if (!this.state.pocAccess) {
                        this.props.history.push('/');
                        return null;
                    }
                });
            })
            .catch(error => {
            });
    };

    fetchUserGroups = (email) => {
        fetch(API_Root + 'get-private-chatbot-groups', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            console.log('Fetched groups:', data.groups);
            this.setState({ groups: data.groups || [] });
        })
        .catch(error => {
            console.error('Error fetching user groups:', error);
        });
    }

    handleFileUpload = () => {
        const { selectedFiles, userEmail } = this.state;
    
        if (selectedFiles.length === 0) {
            this.props.toggleToast({
                show: true,
                type: "warning",
                message: "Please select files for upload.",
            });
            return;
        }
    
        const formData = new FormData();
        selectedFiles.forEach((fileObj) => {
            formData.append('files', fileObj.file);
        });
        formData.append('email', userEmail);
    
        fetch(`${API_Root}/demo-upload`, {
            method: 'POST',
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {
                    throw new Error(data.error);
                }
    
                this.setState({ selectedFiles: [] });
                this.props.toggleToast({
                    show: true,
                    type: "success",
                    message: data.message,
                });
            })
            .catch((error) => {
                console.error('Error uploading files:', error);
                this.props.toggleToast({
                    show: true,
                    type: "error",
                    message: "Failed to upload files. Please try again.",
                });
            });
    };       
    
    handleFileChange = (event) => {
        const files = Array.from(event.target.files).map(file => ({ file, status: 'Pending' }));
        this.setState({ selectedFiles: [...this.state.selectedFiles, ...files] });
    }

    updateFileStatus = (status) => {
        this.setState(prevState => ({
            selectedFiles: prevState.selectedFiles.map(fileObj => ({ ...fileObj, status }))
        }));
    }

    handleFileDelete = (index) => {
        this.setState(prevState => {
            const selectedFiles = prevState.selectedFiles.filter((_, i) => i !== index);
            return { selectedFiles };
        });
    }

    handleMessageChange = (event) => {
        this.setState({ message: event.target.value });
    }

    getChatResponse = () => {
        const { message, selectedModel, session_id, userEmail } = this.state;
    
        if (!message.trim()) {
            return;
        }
    
        this.setState({ isLoading: true });
    
        fetch(`${API_Root}/demo-chat-bedrock`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                prompt: message,
                modelId: selectedModel,
                session_id, // Include session_id
                email: userEmail, // Include userEmail
            }),
        })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    throw new Error(data.error);
                }
    
                const botMessage = {
                    id: data.message_id, // Use the returned message_id
                    sender: "PrivatESRA Bot",
                    text: data.response,
                    citations: data.citations.map(citation => ({
                        filename: citation.filename,
                        content: citation.content.substring(0, 300) + '...',
                    })),
                    type: "response",
                };
    
                this.setState(prevState => ({
                    messages: [
                        ...prevState.messages,
                        { sender: "You", text: message, type: "sent" },
                        botMessage,
                    ],
                    message: "",
                    isLoading: false,
                }));
            })
            .catch(error => {
                console.error("Error interacting with Amazon Bedrock:", error);
                this.setState({ isLoading: false });
            });
    };
         
    
    createNewChatSession = () => {
        const sessionName = "New Session"; // Default session name
        const { userEmail } = this.state;   // Ensure userEmail is set
    
        fetch(`${API_Root}/create-demo-session`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ session_name: sessionName, email: userEmail }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                const newSession = {
                    id: data.session_id,
                    chat_name: data.session_name, // Use 'chat_name' to match existing session mapping
                };
    
                // Update sessions in state
                this.setState(prevState => ({
                    sessions: [...prevState.sessions, newSession],
                }), () => {
                    this.props.toggleToast({
                        show: true,
                        type: "success",
                        message: `Session "${sessionName}" created successfully!`,
                    });
                });
            })
            .catch(error => {
                console.error('Error creating new session:', error);
                this.props.toggleToast({
                    show: true,
                    type: "error",
                    message: "Failed to create a new session. Please try again.",
                });
            });
    };    

    exportSession = (sessionId) => {
        const { userEmail } = this.state;
    
        fetch(`${API_Root}/demo-export-session`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                session_id: sessionId,
                email: userEmail, // Sending the user email
            }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.blob(); // Get the response as a blob
        })
        .then(blob => {
            // Create a temporary URL for the blob
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `session_${sessionId}.csv`; // Set the desired file name
            document.body.appendChild(a);
            a.click(); // Trigger the download
            a.remove();
            window.URL.revokeObjectURL(url);
        })
        .catch(error => {
            console.error("Error exporting session:", error);
            this.props.toggleToast({
                show: true,
                type: "error",
                message: "Failed to export session. Please try again.",
            });
        });
    };     

    fetchDemoSessions = () => {
        const { userEmail } = this.state;
        fetch(`${API_Root}/fetch-demo-sessions`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: userEmail })
        })
            .then(response => {
                console.log('Response status:', response.status); // Debugging log
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                console.log('Fetched sessions:', data.sessions); // Debugging log
                this.setState({ sessions: data.sessions || [] });
            })
            .catch(error => {
                console.error('Error fetching demo sessions:', error);
                this.props.toggleToast({
                    show: true,
                    type: 'error',
                    message: 'Failed to fetch demo sessions. Please try again.',
                });
            });
    };
    

    toggleSidebarMenu = (event, sessionId) => {
        event.stopPropagation(); // Prevent bubbling up
    
        const rect = event.target.getBoundingClientRect();
    
        this.setState((prevState) => {
            console.log("Toggling menu for session:", sessionId);  
            console.log("Previous Menu State:", prevState.isSidebarMenuVisible);
    
            return {
                isSidebarMenuVisible: prevState.selectedSessionId !== sessionId || !prevState.isSidebarMenuVisible,
                menuPosition: {
                    x: rect.left + window.scrollX + 10,
                    y: rect.top + window.scrollY + 10,
                },
                selectedSessionId: sessionId
            };
        }, () => {
            console.log("Updated Menu State:", this.state.isSidebarMenuVisible); // Check if state is updating
        });
    };
    
    
    
    // Close the sidebar menu when clicking outside
    handleClickOutside = (event) => {
        if (this.state.isSidebarMenuVisible && this.sidebarMenuRef && !this.sidebarMenuRef.contains(event.target)) {
            this.setState({ isSidebarMenuVisible: false, selectedSessionId: null });
        }
    };

    handleDeleteFile = (filename) => {
        fetch(`${API_Root}/delete-demo-file`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ filename }),
        })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    throw new Error(data.error);
                }
    
                this.setState(prevState => ({
                    filenames: prevState.filenames.filter(file => file !== filename),
                }));
                this.props.toggleToast({
                    show: true,
                    type: "success",
                    message: `File "${filename}" deleted successfully.`,
                });
            })
            .catch(error => {
                console.error('Error deleting file:', error);
                this.props.toggleToast({
                    show: true,
                    type: "error",
                    message: "Failed to delete file. Please try again.",
                });
            });
    };
    
    
    handleSyncFiles = () => {
        const { userEmail } = this.state;
        fetch(`${API_Root}/demo-sync`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: userEmail })
        })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    throw new Error(data.error);
                }
    
                this.setState({ ingestionJobId: data.ingestionJobId }); // Store the ingestionJobId in state
    
                this.props.toggleToast({
                    show: true,
                    type: "success",
                    message: data.message + ` (Job ID: ${data.ingestionJobId})`,
                });
            })
            .catch(error => {
                console.error('Error syncing files:', error);
                this.props.toggleToast({
                    show: true,
                    type: "error",
                    message: "Failed to sync files. Please try again.",
                });
            });
    };    

    handleCheckSyncStatus = () => {
        const { ingestionJobId, userEmail } = this.state;
    
        if (!ingestionJobId) {
            this.props.toggleToast({
                show: true,
                type: "warning",
                message: "No ingestion job ID found. Please sync files first.",
            });
            return;
        }
    
        fetch(`${API_Root}/demo-check-sync`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ingestionJobId, email: userEmail })
        })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    throw new Error(data.error);
                }
    
                this.props.toggleToast({
                    show: true,
                    type: "info",
                    message: `Ingestion job status: ${data.status}`,
                });
            })
            .catch(error => {
                console.error('Error checking sync status:', error);
                this.props.toggleToast({
                    show: true,
                    type: "error",
                    message: "Failed to check sync status. Please try again.",
                });
            });
    };    
    

    fetchUserSessions = (email) => {
        fetch(API_Root + 'private-chatbot-sessions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                this.setState({ sessions: data.sessions });
            })
            .catch(error => {
                console.error('Error fetching user sessions:', error);
            });
    }

    


    fetchFiles = (email) => {
        fetch(API_Root + 'get-all-private-files', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            if (data.error) {
                throw new Error(data.error);
            }
            this.setState({ filenames: data.filenames });
        })
        .catch(error => {
            console.error('Error fetching files:', error);
        });
    }

    deleteDemoSession = (sessionId) => {
        fetch(`${API_Root}/delete-demo-session`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ session_id: sessionId }),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            if (data.success) {
                // Update state to remove the session from the list
                this.setState(prevState => ({
                    sessions: prevState.sessions.filter(session => session.id !== sessionId),
                }));
                this.props.toggleToast({
                    show: true,
                    type: 'success',
                    message: `Session "${sessionId}" deleted successfully.`,
                });
            } else {
                throw new Error(data.message || 'Failed to delete session.');
            }
        })
        .catch(error => {
            console.error('Error deleting session:', error);
            this.props.toggleToast({
                show: true,
                type: 'error',
                message: `Failed to delete session. ${error.message}`,
            });
        });
    };
    

    selectChatSession = (session_id) => {
        this.setState({ session_id, messages: [] }); // Reset messages when a new session is selected
    
        fetch(`${API_Root}/demo-fetch-session-messages`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ session_id }),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => {
                if (data.messages) {
                    this.setState({ messages: data.messages });
                }
            })
            .catch((error) => {
                console.error('Error fetching session messages:', error);
                this.props.toggleToast({
                    show: true,
                    type: 'error',
                    message: 'Failed to fetch messages for the selected session.',
                });
            });
    };    

    

    truncateSessionName = (name) => {
        const words = name.split(' ');
        if (words.length <= 3) {
            return name;
        }
        return words.slice(0, 3).join(' ') + '...';
    }

    deleteSession = () => {
        const { selectedSessionId } = this.state;
    
        fetch(API_Root + 'delete-private-chatbot-session', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ session_id: selectedSessionId }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                // Refresh the session list
                this.fetchUserSessions(this.state.userEmail);
                this.closeModal();
            })
            .catch(error => {
                console.error('Error deleting session:', error);
                this.props.toggleToast({ show: true, type: "error", message: "Error deleting session, please try again." });
            });
    };

    handleDeleteAll = () => {
        const { userEmail } = this.state;
    
        fetch(API_Root + 'private-chatbot-delete-all', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: userEmail }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                this.props.toggleToast({ show: true, type: "success", message: "All files and vectors deleted successfully" });
                this.setState({ filenames: [] });
            })
            .catch(error => {
                console.error('Error deleting all files:', error);
                this.props.toggleToast({ show: true, type: "error", message: "Error deleting all files, please try again." });
            });
    }

    handleDeleteTeam = (teamName) => {
        fetch(`${API_Root}/delete-demo-team`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ team_name: teamName }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                this.props.toggleToast({
                    show: true,
                    type: "success",
                    message: `Team "${teamName}" deleted successfully!`,
                });
                // Remove the team from the state
                this.setState(prevState => ({
                    teams: prevState.teams.filter(team => team !== teamName),
                }));
            })
            .catch(error => {
                console.error('Error deleting team:', error);
                this.props.toggleToast({
                    show: true,
                    type: "error",
                    message: `Failed to delete team "${teamName}". Please try again.`,
                });
            });
    };

    renameSession = (sessionId) => {
        const { newSessionName } = this.state;
    
        if (!newSessionName.trim()) {
            alert("Session name cannot be empty.");
            return;
        }
    
        fetch(`${API_Root}/demo-rename-session`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                session_id: sessionId,
                new_name: newSessionName,
            }),
        })
        .then(response => response.json())
        .then(data => {
            if (data.error) {
                throw new Error(data.error);
            }
    
            this.props.toggleToast({
                show: true,
                type: "success",
                message: "Session renamed successfully!",
            });
    
            this.setState({
                isRenaming: false,
                newSessionName: "",
                isSmallModalVisible: false, // Close modal after rename
            });
    
            // Optionally refresh sessions list here
        })
        .catch(error => {
            console.error("Error renaming session:", error);
            this.props.toggleToast({
                show: true,
                type: "error",
                message: "Failed to rename session. Please try again.",
            });
        });
    };
    
    

    render() {

        const { message, messages, isLoading, isSidebarOpen, selectedFiles, sessions, isOwner, instanceExists, isAdmin, groups, showTeamMembers, isModalVisible, modalPosition } = this.state;
        console.log(this.state)
        return (
            <div>
                <header className="header">
                    <div className="header-left">
                        <button onClick={this.toggleSidebar} className="menu-icon-btn" aria-label='Expand or shrink sidebar button'>
                            <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" focusable="false" className="menu-icon1">
                                <g><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path></g>
                            </svg>

                        </button>
                        <div className='logo' />
                        <span className='logo-text' role="heading"><Link to="/" style={{ color: '#3E826D' }}>privatESRA</Link></span>
                    </div>
                    <div className="header-right">
                        <select
                            className="model-select"
                            value={this.state.selectedModel}
                            onChange={(e) => this.setState({ selectedModel: e.target.value })}
                            aria-label="Select model"
                        >
                            <option value="llama38b">Llama 3 8B</option>
                            <option value="llama370b">Llama 3 70B</option>
                            <option value="claude_instant">Claude Instant</option>
                            <option value="claude2_1">Claude 2.1</option>
                            <option value="mistral7b">Mistral 7B</option>
                        </select>

                    </div>
                </header>
                <div className='content'>
                    <div className={`sidebar${isSidebarOpen ? ' open' : ''}`} id="sidebar" role="sidebar" aria-label="Sidebar">
                        <div className="hidden-sidebar">
                            <div className='top-sidebar'>
                                <button 
                                    className={`new-chat-button ${this.state.isSidebarOpen ? 'expanded' : 'collapsed'}`} 
                                    aria-label='New chat button' 
                                    onClick={this.createNewChatSession}
                                >
                                    <svg 
                                        className='sidebar-icon' 
                                        xmlns="http://www.w3.org/2000/svg" 
                                        width="28" height="28" 
                                        viewBox="0 0 24 24" 
                                        fill="none" 
                                        stroke="currentColor" 
                                        strokeWidth="2" 
                                        strokeLinecap="round" 
                                        strokeLinejoin="round"
                                    >
                                        <circle cx="12" cy="12" r="10"></circle>
                                        <line x1="12" y1="8" x2="12" y2="16"></line>
                                        <line x1="8" y1="12" x2="16" y2="12"></line>
                                    </svg>
                                    <span className="button-text">New Chat</span>
                                </button>
                            </div>

                            <div className="middle-sidebar">
                                {sessions.map(session => (
                                    <div key={session.id} className="session-container" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", position: "relative" }}>
                                        <button
                                            className="sidebar-item"
                                            aria-label="Chat conversation example"
                                            onClick={() => this.selectChatSession(session.id)}
                                        >
                                            <div className="sidebar-item-left">
                                                <svg
                                                    className="sidebar-icon"
                                                    aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    fill="currentColor"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path d="M3 6a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-6.616l-2.88 2.592C8.537 20.461 7 19.776 7 18.477V17H5a2 2 0 0 1-2-2V6Zm4 2a1 1 0 0 0 0 2h5a1 1 0 1 0 0-2H7Zm8 0a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2h-2Zm-8 3a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2H7Zm5 0a1 1 0 1 0 0 2h5a1 1 0 1 0 0-2h-5Z" />
                                                </svg>
                                            </div>
                                            <div className="sidebar-item-right">
                                                {session.session_name}
                                            </div>
                                            <button
                                                className="dots-icon"
                                                aria-label="Session Options"
                                                onClick={() => this.openSmallModal(session.id)}
                                            >
                                                
                                            </button>
                                        </button>
                                    
                                        
                                        {this.state.isSmallModalVisible &&
                                            ReactDOM.createPortal(
                                                <div className="small-modal-overlay" onClick={this.closeSmallModal}>
                                                    <div className="small-modal-box" onClick={(e) => e.stopPropagation()}>
                                                        <p>Session Options</p>
                                                        {this.state.isRenaming ? (
                                                            <>
                                                                <input
                                                                    type="text"
                                                                    placeholder="Enter new session name"
                                                                    value={this.state.newSessionName}
                                                                    onChange={(e) => this.setState({ newSessionName: e.target.value })}
                                                                />
                                                                <button onClick={() => this.renameSession(this.state.selectedSessionId)}>
                                                                    Confirm Rename
                                                                </button>
                                                                <button onClick={() => this.setState({ isRenaming: false, newSessionName: "" })}>
                                                                    Cancel
                                                                </button>
                                                            </>
                                                        ) : (
                                                            <button onClick={() => this.setState({ isRenaming: true })}>
                                                                Rename
                                                            </button>
                                                        )}

                                                        <button onClick={() => {
                                                            if (this.state.selectedSessionId) {
                                                                this.deleteDemoSession(this.state.selectedSessionId);
                                                                this.closeSmallModal();
                                                            }
                                                        }}>
                                                            Delete
                                                        </button>

                                                        <button onClick={() => {
                                                            if (this.state.selectedSessionId) {
                                                                this.exportSession(this.state.selectedSessionId);
                                                                this.closeSmallModal();
                                                            }
                                                        }}>
                                                            Export
                                                        </button>

                                                        <button onClick={this.closeSmallModal}>Close</button>
                                                    </div>
                                                </div>,
                                                document.body
                                            )
                                        }
                                    </div>
                                ))}
                            </div>

                            {isModalVisible && (
                                <div
                                    className="context-menu"
                                    style={{
                                        position: 'absolute',
                                        top: modalPosition.y,
                                        left: modalPosition.x,
                                        backgroundColor: 'white',
                                        border: '1px solid #ccc',
                                        padding: '10px',
                                        zIndex: 1000,
                                    }}
                                >
                                    <button className="delete-session-btn" onClick={this.deleteSession}>Delete</button>
                                </div>
                            )}
                            <div className='bottom-sidebar'>
                                <div className='user-email-container'>
                                    <div className='user-email'>
                                    <strong>
                                        {this.state.firstName && this.state.lastName
                                            ? `${this.state.firstName} ${this.state.lastName}`
                                            : 'Loading...'}
                                    </strong>
                                    </div>
                                </div>
                                <button className='sidebar-item' aria-label='myESRA'>
                                    <div className='sidebar-item-left'>
                                        <svg className="sidebar-icon" fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="64px" height="64px" viewBox="0 0 92 92"><path id="XMLID_223_" d="M82,32.3V72c0,2.2-1.8,4-4,4H19.1c-2.2,0-4-1.8-4-4s1.8-4,4-4H74V36H23.7l5.5,5.6c1.6,1.6,1.6,4.2,0,5.8 c-0.8,0.8-1.8,1.2-2.8,1.2c-1,0-2-0.4-2.8-1.2L11.2,35.1c-0.8-0.8-1.2-1.8-1.2-2.8c0-1.1,0.4-2.1,1.2-2.8l12.4-12.3 c1.6-1.6,4.1-1.6,5.7,0s1.5,4,0,5.6L23.7,28H78C80.2,28,82,30.1,82,32.3z"></path></svg>
                                    </div>
                                    <div className='sidebar-item-right'>
                                        <Link to="/chat" style={{ color: '#000', textDecoration: 'none' }}>myESRA</Link>
                                    </div>
                                </button>
                                {(this.state.isAdmin || this.state.userEmail === 'ildiko@ildiko-almasi.com' || this.state.userEmail === 'joseph@woyera.com') && (
                                    <button className='sidebar-item' aria-label='Admin button' onClick={this.openAdminModal}>
                                        <div className='sidebar-item-left'>
                                            <svg className="sidebar-icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 640 512">
                                                <path d="M0 24C0 10.7 10.7 0 24 0H616c13.3 0 24 10.7 24 24s-10.7 24-24 24H24C10.7 48 0 37.3 0 24zM0 488c0-13.3 10.7-24 24-24H616c13.3 0 24 10.7 24 24s-10.7 24-24 24H24c-13.3 0-24-10.7-24-24zM83.2 160a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zM32 320c0-35.3 28.7-64 64-64h96c12.2 0 23.7 3.4 33.4 9.4c-37.2 15.1-65.6 47.2-75.8 86.6H64c-17.7 0-32-14.3-32-32zm461.6 32c-10.3-40.1-39.6-72.6-77.7-87.4c9.4-5.5 20.4-8.6 32.1-8.6h96c35.3 0 64 28.7 64 64c0 17.7-14.3 32-32 32H493.6zM391.2 290.4c32.1 7.4 58.1 30.9 68.9 61.6c3.5 10 5.5 20.8 5.5 32c0 17.7-14.3 32-32 32h-224c-17.7 0-32-14.3-32-32c0-11.2 1.9-22 5.5-32c10.5-29.7 35.3-52.8 66.1-60.9c7.8-2.1 16-3.1 24.5-3.1h96c7.4 0 14.7 .8 21.6 2.4zm44-130.4a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zM321.6 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z" />
                                            </svg>
                                        </div>
                                        <div className='sidebar-item-right'>
                                            Admin
                                        </div>
                                    </button>
                                )}
                                {this.state.isAdminModalOpen && ReactDOM.createPortal(
                                    <div className="modal-box">
                                        <div className="modal-content">
                                            <h2>Admin Panel</h2>

                                            {/* Create Team Section */}
                                            <button onClick={() => this.setState({ showTeamInput: true })}>Create Team</button>
                                            {this.state.showTeamInput && (
                                                <div>
                                                    <input
                                                        type="text"
                                                        placeholder="Enter team name"
                                                        value={this.state.newTeamName}
                                                        onChange={(e) => this.setState({ newTeamName: e.target.value })}
                                                    />
                                                    <br />
                                                    <button onClick={this.addTeam}>Add Team</button>
                                                </div>
                                            )}

                                            {this.state.teams.length > 0 ? (
                                                <div>
                                                    <h3>Teams</h3>
                                                    <ul>
                                                        {this.state.teams.map((team, index) => (
                                                            <li key={index} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                                <span>{team}</span>
                                                                <div style={{ display: "flex", gap: "10px" }}>
                                                                    <button
                                                                        style={{
                                                                            padding: "5px 10px",
                                                                            backgroundColor: "#3e826d",
                                                                            color: "white",
                                                                            border: "none",
                                                                            borderRadius: "4px",
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() => this.handleInviteToTeam(team)}
                                                                    >
                                                                        Invite
                                                                    </button>
                                                                    <button
                                                                        style={{
                                                                            padding: "5px 10px",
                                                                            backgroundColor: "#f0ad4e",
                                                                            color: "white",
                                                                            border: "none",
                                                                            borderRadius: "4px",
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() => this.openEditTeamModal(team)}
                                                                    >
                                                                        Edit
                                                                    </button>
                                                                    <button
                                                                        style={{
                                                                            padding: "5px 10px",
                                                                            backgroundColor: "#dc3545",
                                                                            color: "white",
                                                                            border: "none",
                                                                            borderRadius: "4px",
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() => this.handleDeleteTeam(team)}
                                                                    >
                                                                        Delete
                                                                    </button>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            ) : (
                                                <p>No teams found.</p>
                                            )}

                                            {this.state.isEditTeamModalOpen && ReactDOM.createPortal(
                                                <div className="modal-box">
                                                    <div className="modal-content">
                                                        <h2>Edit Team: {this.state.selectedTeamForEdit}</h2>
                                                        {this.state.editTeamMembers.length > 0 ? (
                                                            <ul>
                                                                {this.state.editTeamMembers.map((member, index) => (
                                                                    <li key={index} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                        <span>{member.email} - {member.role}</span>
                                                                        <button
                                                                            style={{
                                                                                padding: "5px 10px",
                                                                                backgroundColor: "#dc3545",
                                                                                color: "white",
                                                                                border: "none",
                                                                                borderRadius: "4px",
                                                                                cursor: "pointer",
                                                                            }}
                                                                            onClick={() => this.removeTeamMember(member.email)}
                                                                        >
                                                                            Remove
                                                                        </button>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        ) : (
                                                            <p>No team members found.</p>
                                                        )}
                                                        <button
                                                            style={{
                                                                marginTop: "10px",
                                                                padding: "10px 20px",
                                                                backgroundColor: "gray",
                                                                color: "white",
                                                                border: "none",
                                                                borderRadius: "4px",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={this.closeEditTeamModal}
                                                        >
                                                            Close
                                                        </button>
                                                    </div>
                                                </div>,
                                                document.body
                                            )}
                                            {/* Invite Members Modal */}
                                            {this.state.isInviteModalOpen && ReactDOM.createPortal(
                                                <div className="modal-box">
                                                    <div className="modal-content">
                                                        <h2>Invite Members to {this.state.selectedTeam}</h2>
                                                        <ul>
                                                        {this.state.organizationEmails.map((email, index) => (
                                                            <li key={index}>
                                                            <span>{email}</span>
                                                            <select
                                                                onChange={(e) => this.handleRoleChange(email, e.target.value)}
                                                                value={this.state.selectedRoles[email] || "user"}
                                                            >
                                                                <option value="user">User</option>
                                                                <option value="admin">Admin</option>
                                                            </select>
                                                            <input
                                                                type="checkbox"
                                                                onChange={(e) => this.handleSelectInvite(email, e.target.checked)}
                                                                checked={this.state.selectedInvites.includes(email)}
                                                            />
                                                            </li>
                                                        ))}
                                                        </ul>

                                                        <button
                                                            style={{
                                                                marginTop: "10px",
                                                                padding: "10px 20px",
                                                                backgroundColor: "#3e826d",
                                                                color: "white",
                                                                border: "none",
                                                                borderRadius: "4px",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={this.handleSendInvites}
                                                        >
                                                            Assign Members
                                                        </button>
                                                        <button
                                                            style={{
                                                                marginTop: "10px",
                                                                marginLeft: "10px",
                                                                padding: "10px 20px",
                                                                backgroundColor: "gray",
                                                                color: "white",
                                                                border: "none",
                                                                borderRadius: "4px",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={this.closeInviteModal}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>,
                                                document.body
                                            )}
                                            <div className="files-for-approval">
                                                <h3>Files Pending Approval</h3>
                                                <ul>
                                                    {this.state.filesForApproval.map((file, index) => (
                                                        <li key={file.id} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px" }}>
                                                            <div>
                                                                <span className="file-name" style={{ marginRight: "10px" }}>{file.filename}</span>
                                                            </div>
                                                            <div>
                                                                <button
                                                                    style={{
                                                                        padding: "5px 10px",
                                                                        backgroundColor: "#3e826d",
                                                                        color: "white",
                                                                        border: "none",
                                                                        borderRadius: "4px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={() => this.approveFile(file.id)}
                                                                >
                                                                    Approve
                                                                </button>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <div>
                                                <h3>Sync Data</h3>
                                                <button
                                                    style={{
                                                        marginTop: "10px",
                                                        padding: "10px 20px",
                                                        backgroundColor: "#3e826d",
                                                        color: "white",
                                                        border: "none",
                                                        borderRadius: "4px",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={this.handleSyncFiles}
                                                >
                                                    Sync Uploaded Files
                                                </button>
                                                <button
                                                    style={{
                                                        marginTop: "10px",
                                                        marginLeft: "10px",
                                                        padding: "10px 20px",
                                                        backgroundColor: "#007bff",
                                                        color: "white",
                                                        border: "none",
                                                        borderRadius: "4px",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={this.handleCheckSyncStatus}
                                                >
                                                    Check Sync Status
                                                </button>
                                            </div>
                                            <div>
                                                <h3>Files</h3>
                                                <button
                                                    style={{
                                                        marginTop: "10px",
                                                        padding: "10px 20px",
                                                        backgroundColor: "#6c757d",
                                                        color: "white",
                                                        border: "none",
                                                        borderRadius: "4px",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={this.fetchDemoFiles}
                                                >
                                                    Refresh Files
                                                </button>
                                                {this.state.filenames.length > 0 && (
                                                    <ul style={{ marginTop: "10px" }}>
                                                        {this.state.filenames.map((filename, index) => (
                                                            <li key={index} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                                <span>{filename}</span>
                                                                <button
                                                                    style={{
                                                                        padding: "5px 10px",
                                                                        backgroundColor: "#dc3545",
                                                                        color: "white",
                                                                        border: "none",
                                                                        borderRadius: "4px",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={() => this.handleDeleteFile(filename)}
                                                                >
                                                                    Delete
                                                                </button>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                            <button onClick={this.closeAdminModal}>Close</button>
                                        </div>
                                    </div>,
                                    document.body
                                )}

                                

                                {this.state.isInviteModalOpen && ReactDOM.createPortal(
                                    <div className="modal-box">
                                        <div className="modal-content">
                                            <h2>Invite Members to {this.state.selectedTeam}</h2>
                                            <ul>
                                                {this.state.organizationEmails.map((email, index) => (
                                                    <li key={index} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                        <span>{email}</span>
                                                        <select
                                                            style={{ marginLeft: "10px", padding: "5px", borderRadius: "4px" }}
                                                            onChange={(e) => this.handleRoleChange(email, e.target.value)}
                                                            value={this.state.selectedRoles[email] || "user"}
                                                        >
                                                            <option value="user">User</option>
                                                            <option value="admin">Admin</option>
                                                        </select>
                                                        <input
                                                            type="checkbox"
                                                            style={{ marginLeft: "10px" }}
                                                            onChange={(e) => this.handleSelectInvite(email, e.target.checked)}
                                                            checked={this.state.selectedInvites.includes(email)}
                                                        />
                                                    </li>
                                                ))}
                                            </ul>
                                            <button
                                                style={{
                                                    marginTop: "10px",
                                                    padding: "10px 20px",
                                                    backgroundColor: "#3e826d",
                                                    color: "white",
                                                    border: "none",
                                                    borderRadius: "4px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={this.handleSendInvites}
                                            >
                                                Assign Members
                                            </button>
                                            <button
                                                style={{
                                                    marginTop: "10px",
                                                    marginLeft: "10px",
                                                    padding: "10px 20px",
                                                    backgroundColor: "gray",
                                                    color: "white",
                                                    border: "none",
                                                    borderRadius: "4px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={this.closeInviteModal}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>,
                                    document.body
                                )}
                                



                                <div className={`button-container ${showTeamMembers ? 'expanded' : ''}`}>
                                    <button
                                        className='sidebar-item'
                                        aria-label='Show Team Members'
                                        onClick={() => this.setState((prevState) => ({ showTeamMembers: !prevState.showTeamMembers }))}
                                    >
                                        <div className='sidebar-item-left'>
                                            <svg className="sidebar-icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 640 512">
                                                <path d="M0 24C0 10.7 10.7 0 24 0H616c13.3 0 24 10.7 24 24s-10.7 24-24 24H24C10.7 48 0 37.3 0 24zM0 488c0-13.3 10.7-24 24-24H616c13.3 0 24 10.7 24 24s-10.7 24-24 24H24c-13.3 0-24-10.7-24-24zM83.2 160a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zM32 320c0-35.3 28.7-64 64-64h96c12.2 0 23.7 3.4 33.4 9.4c-37.2 15.1-65.6 47.2-75.8 86.6H64c-17.7 0-32-14.3-32-32zm461.6 32c-10.3-40.1-39.6-72.6-77.7-87.4c9.4-5.5 20.4-8.6 32.1-8.6h96c35.3 0 64 28.7 64 64c0 17.7-14.3 32-32 32H493.6zM391.2 290.4c32.1 7.4 58.1 30.9 68.9 61.6c3.5 10 5.5 20.8 5.5 32c0 17.7-14.3 32-32 32h-224c-17.7 0-32-14.3-32-32c0-11.2 1.9-22 5.5-32c10.5-29.7 35.3-52.8 66.1-60.9c7.8-2.1 16-3.1 24.5-3.1h96c7.4 0 14.7 .8 21.6 2.4zm44-130.4a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zM321.6 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z" />
                                            </svg>
                                        </div>
                                        <div className='sidebar-item-right'>Team</div>
                                    </button>

                                    {showTeamMembers && (
                                        <div className="team-container">
                                            {this.state.teamMembers.length > 0 ? (
                                                <>
                                                    <div className="team-member-header">
                                                        <div className="team-member-column">Email</div>
                                                        <div className="team-member-column">Role</div>
                                                    </div>
                                                    {this.state.teamMembers.map((member, index) => (
                                                        <div key={index} className="team-member">
                                                            <div className="team-member-column-left">{member.email}</div>
                                                            <div className="team-member-column-right">{member.role}</div>
                                                        </div>
                                                    ))}
                                                </>
                                            ) : (
                                                <p>No team members found.</p>
                                            )}
                                        </div>
                                    )}
                                </div>


                                {isOwner && !instanceExists && (
                                    <button className='sidebar-item' aria-label='Create Instance' onClick={this.createInstance}>
                                        <div className='sidebar-item-left'>
                                            <svg className="sidebar-icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                                <path fill-rule="evenodd" d="M3 6a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-6.616l-2.88 2.592C8.537 20.461 7 19.776 7 18.477V17H5a2 2 0 0 1-2-2V6Zm4 2a1 1 0 0 0 0 2h5a1 1 0 1 0 0-2H7Zm8 0a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2h-2Zm-8 3a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2H7Zm5 0a1 1 0 1 0 0 2h5a1 1 0 1 0 0-2h-5Z" clip-rule="evenodd" />
                                            </svg>
                                        </div>
                                        <div className='sidebar-item-right'>
                                            Create Instance
                                        </div>
                                    </button>
                                )}

                                {/* {groups && groups.length > 0 && (
                                    <div className='groups-section'>
                                        {groups.map(group => (
                                            <div key={group.group_id} className='group'>
                                                <button className='sidebar-item'>
                                                    <div className='sidebar-item-left'>
                                                        <svg className="sidebar-icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 640 512">
                                                            <path d="M0 24C0 10.7 10.7 0 24 0H616c13.3 0 24 10.7 24 24s-10.7 24-24 24H24C10.7 48 0 37.3 0 24zM0 488c0-13.3 10.7-24 24-24H616c13.3 0 24 10.7 24 24s-10.7 24-24 24H24c-13.3 0-24-10.7-24-24zM83.2 160a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zM32 320c0-35.3 28.7-64 64-64h96c12.2 0 23.7 3.4 33.4 9.4c-37.2 15.1-65.6 47.2-75.8 86.6H64c-17.7 0-32-14.3-32-32zm461.6 32c-10.3-40.1-39.6-72.6-77.7-87.4c9.4-5.5 20.4-8.6 32.1-8.6h96c35.3 0 64 28.7 64 64c0 17.7-14.3 32-32 32H493.6zM391.2 290.4c32.1 7.4 58.1 30.9 68.9 61.6c3.5 10 5.5 20.8 5.5 32c0 17.7-14.3 32-32 32h-224c-17.7 0-32-14.3-32-32c0-11.2 1.9-22 5.5-32c10.5-29.7 35.3-52.8 66.1-60.9c7.8-2.1 16-3.1 24.5-3.1h96c7.4 0 14.7 .8 21.6 2.4zm44-130.4a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zM321.6 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z" />
                                                        </svg>
                                                    </div>
                                                    {isSidebarOpen && (
                                                        <div className='sidebar-item-right'>
                                                            {group.group_name}
                                                        </div>
                                                    )}
                                                </button>
                                                {isSidebarOpen && (
                                                    <ul>
                                                        {group.members.map(member => (
                                                            <li key={member.user_id}>{member.first_name} {member.last_name}</li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                )} */}


                                {/* {groups && groups.length > 0 && (
                                <div className='groups-section'>
                                    {groups.map(group => (
                                        <div key={group.group_id} className='group'>
                                            <button className='sidebar-item'>
                                                <div className='sidebar-item-left'>
                                                    <svg className="sidebar-icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 640 512">
                                                        <path d="M0 24C0 10.7 10.7 0 24 0H616c13.3 0 24 10.7 24 24s-10.7 24-24 24H24C10.7 48 0 37.3 0 24zM0 488c0-13.3 10.7-24 24-24H616c13.3 0 24 10.7 24 24s-10.7 24-24 24H24c-13.3 0-24-10.7-24-24zM83.2 160a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zM32 320c0-35.3 28.7-64 64-64h96c12.2 0 23.7 3.4 33.4 9.4c-37.2 15.1-65.6 47.2-75.8 86.6H64c-17.7 0-32-14.3-32-32zm461.6 32c-10.3-40.1-39.6-72.6-77.7-87.4c9.4-5.5 20.4-8.6 32.1-8.6h96c35.3 0 64 28.7 64 64c0 17.7-14.3 32-32 32H493.6zM391.2 290.4c32.1 7.4 58.1 30.9 68.9 61.6c3.5 10 5.5 20.8 5.5 32c0 17.7-14.3 32-32 32h-224c-17.7 0-32-14.3-32-32c0-11.2 1.9-22 5.5-32c10.5-29.7 35.3-52.8 66.1-60.9c7.8-2.1 16-3.1 24.5-3.1h96c7.4 0 14.7 .8 21.6 2.4zm44-130.4a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zM321.6 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z" />
                                                    </svg>
                                                </div>
                                                {isSidebarOpen && (
                                                    <div className='sidebar-item-right'>
                                                        {group.group_name}
                                                    </div>
                                                )}
                                            </button>
                                            {isSidebarOpen && (
                                                <ul>
                                                    {group.members.map(member => (
                                                        <li key={member.user_id}>{member.username}</li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )} */}

                                
                                <button className='sidebar-item' aria-label='File Upload' onClick={() => this.fileInputRef.click()}>
                                    <div className='sidebar-item-left'>
                                        <svg className="sidebar-icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 96 960 960">
                                            <path d="M480 776q-17 0-28.5-11.5T440 736V564l-69 69q-12 12-28 12t-28-12q-11-11-11.5-27.5T315 578l161-161q6-6 13.5-9.5T504 404q8 0 15.5 3.5T533 417l160 161q12 12 11.5 28T693 634q-11 12-28 12t-28-12l-69-69v172q0 17-11.5 28.5T480 776ZM240 936q-33 0-56.5-23.5T160 856V696q0-17 11.5-28.5T200 656q17 0 28.5 11.5T240 696v160h480V696q0-17 11.5-28.5T760 656q17 0 28.5 11.5T800 696v160q0 33-23.5 56.5T720 936H240Z"/>
                                        </svg>
                                    </div>
                                    <div className='sidebar-item-right'>
                                        Upload Files
                                    </div>
                                    <input
                                        type="file"
                                        multiple
                                        style={{ display: 'none' }}
                                        ref={(ref) => this.fileInputRef = ref}
                                        onChange={this.handleFileChange}
                                    />
                                </button>
                                

                                <button className='sidebar-item' aria-label='Profile button'>
                                    <div className='sidebar-item-left'>
                                        <svg className="sidebar-icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                                            <path fill-rule="evenodd" d="M4 4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H4Zm10 5a1 1 0 0 1 1-1h3a1 1 0 1 1 0 2h-3a1 1 0 0 1-1-1Zm0 3a1 1 0 0 1 1-1h3a1 1 0 1 1 0 2h-3a1 1 0 0 1-1-1Zm0 3a1 1 0 0 1 1-1h3a1 1 0 1 1 0 2h-3a1 1 0 0 1-1-1Zm-8-5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm1.942 4a3 3 0 0 0-2.847 2.051l-.044.133-.004.012c-.042.126-.055.167-.042.195.006.013.02.023.038.039.032.025.08.064.146.155A1 1 0 0 0 6 17h6a1 1 0 0 0 .811-.415.713.713 0 0 1 .146-.155c.019-.016.031-.026.038-.04.014-.027 0-.068-.042-.194l-.004-.012-.044-.133A3 3 0 0 0 10.059 14H7.942Z" clip-rule="evenodd" />
                                        </svg>
                                    </div>
                                    <div className='sidebar-item-right'>
                                        Profile
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <main id="main-content" role="main" aria-label="Main content">

                        <div className="chatbox">
                            <div className="chat-messages">
                                {messages.length === 0 ? (
                                    <div className='welcome-message'>
                                        <div className="welcome-logo" />
                                        <span role="heading"><h1>Welcome to privatESRA</h1></span>
                                        <div className='sample-prompts-container'>
                                            <ol>
                                                <li>Upload files to your private knowledge base</li>
                                                <li>Resolve errors, if any, to complete upload</li>
                                                <li>Ask the privatESRA questions about the knowledge base</li>
                                                <li>Delete files & conversation history</li>
                                            </ol>
                                        </div>
                                    </div>
                                ) : (
                                    messages.map((msg, index) => (
                                        <div key={index} className={`message-${msg.type}`}>
                                            <span className="user-name">{msg.sender}:</span>
                                            <div className="message-text">
                                                {msg.text.split('\n').map((line, idx) => (
                                                    <React.Fragment key={idx}>
                                                        {line}
                                                        <br />
                                                    </React.Fragment>
                                                ))}
                                            </div>

                                            {msg.citations && msg.citations.length > 0 && (
                                                <div className="citation-container">
                                                    {msg.citations.map((citation, idx) => (
                                                        <div key={idx} className="citation-box">
                                                            Source
                                                            <span className="tooltip">
                                                                <strong>{citation.filename}</strong>
                                                                <br />
                                                                {citation.content}
                                                            </span>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                            {(msg.sender === "Privatesra Bot" || msg.sender === "Bot") && (
                                                <div className="feedback-buttons" style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
                                                    <button
                                                        className="private-thumbs-up"
                                                        onClick={(e) => this.openFeedbackModal({ ...msg, feedback_status: true }, e)}
                                                        aria-label="Thumbs Up"
                                                    ></button>

                                                    <button
                                                        className="private-thumbs-down"
                                                        onClick={(e) => this.openFeedbackModal({ ...msg, feedback_status: false }, e)}
                                                        aria-label="Thumbs Down"
                                                    ></button>
                                                </div>
                                            )}
                                            {msg.model_id && (
                                                <small className="model-id">Model: {msg.model_id}</small>
                                            )}
                                            
                                            {this.state.isFeedbackModalVisible && ReactDOM.createPortal(
                                                <div
                                                    className="feedback-modal"
                                                    style={{
                                                        position: "absolute",
                                                        top: `${this.state.modalPosition.y}px`,
                                                        left: `${this.state.modalPosition.x}px`,
                                                        width: "180px",
                                                        height: "150px",
                                                        backgroundColor: "white",
                                                        border: "1px solid #ccc",
                                                        borderRadius: "8px",
                                                        padding: "10px",
                                                        zIndex: 999999,
                                                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                                    }}
                                                >
                                                    <textarea
                                                        rows="3"
                                                        style={{
                                                            width: "100%",
                                                            padding: "5px",
                                                            borderRadius: "4px",
                                                            border: "1px solid #ccc",
                                                            fontSize: "12px",
                                                        }}
                                                        placeholder="Enter feedback..."
                                                        value={this.state.feedbackMessage}
                                                        onChange={(e) => this.setState({ feedbackMessage: e.target.value })}
                                                    />
                                                    <div style={{ marginTop: "10px", display: "flex", justifyContent: "space-between" }}>
                                                        <button
                                                            style={{
                                                                padding: "5px 10px",
                                                                backgroundColor: "#007bff",
                                                                color: "white",
                                                                border: "none",
                                                                borderRadius: "4px",
                                                                fontSize: "12px",
                                                            }}
                                                            onClick={this.submitFeedback}
                                                        >
                                                            Send
                                                        </button>
                                                        <button
                                                            style={{
                                                                padding: "5px 10px",
                                                                backgroundColor: "gray",
                                                                color: "white",
                                                                border: "none",
                                                                borderRadius: "4px",
                                                                fontSize: "12px",
                                                            }}
                                                            onClick={this.closeFeedbackModal}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>,
                                                document.body
                                            )}



                                        </div>
                                    ))
                                )}
                            </div>


                            <div className="message-input">
                                <input
                                    id="message-input"
                                    type="text"
                                    aria-label='Input bar. Type your prompt here'
                                    value={message}
                                    onChange={this.handleMessageChange}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault(); // Prevent form submission or default behavior
                                            this.getChatResponse(); // Trigger the send action
                                        }
                                    }}
                                    placeholder="Type a message..."
                                />
                                <button id="send-btn" aria-label='Send prompt/question button' onClick={this.getChatResponse} disabled={isLoading}>
                                    {isLoading ? (
                                        <div className="loader"></div>
                                    ) : (
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M5 12h13M12 5l7 7-7 7" /></svg>
                                    )}
                                </button>
                            </div>
                        </div>
                        <div className="right-section">
                                <div className="right-table">
                                    {selectedFiles.length > 0 && (
                                        <div className="file-bubbles">
                                            {selectedFiles.map((fileObj, index) => (
                                                <div key={index} className="file-bubble">
                                                    <div className="file-info">
                                                        <span className="file-name">{fileObj.file.name}</span>
                                                        <span className="file-status">{fileObj.status}</span>
                                                    </div>
                                                    <button className="delete-btn" onClick={() => this.handleFileDelete(index)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                            <line x1="18" y1="6" x2="6" y2="18"></line>
                                                            <line x1="6" y1="6" x2="18" y2="18"></line>
                                                        </svg>
                                                    </button>
                                                </div>
                                            ))}
                                            <button className="upload-btn" onClick={this.handleFileUpload} disabled={isLoading}>
                                                {isLoading ? 'Uploading...' : 'Upload'}
                                            </button>
                                        </div>
                                    )}
                                </div>
                                
                                <div className="file-list">
                                    <h1>Files in Database</h1>
                                    <ul>
                                        {this.state.filenames.map((filename, index) => (
                                            <li key={index}>{filename}</li>
                                        ))}
                                    </ul>
                                </div>
                                
                            </div>
                    </main>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({});

const mapActionsToProps = { toggleToast };

export default withRouter(connect(mapStateToProps, mapActionsToProps)(PrivateChatbot));